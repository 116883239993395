import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Card, Input, Button } from 'antd';
import { itemActions } from '../../actions/item';
import { Link } from 'react-router-dom';

const ItemEdit = (props) => {
	const dispatch = useDispatch();
	const [ state, setState ] = useState('');
	const data = useSelector((state) => state.items.singleItem);
	const loading = useSelector((state) => state.items.loading);
	useEffect(
		() => {
			const { id } = props.match.params;
			dispatch(itemActions.detailItem({ id }));
			setState(data.name);
		},
		[ data.name, dispatch, props.match.params ]
	);

	const updateAddress = () => {
		const payload = {
			id   : data.id,
			name : state
		};
		dispatch(itemActions.updateItem(payload));
	};

	return (
		<Drawer>
			<Spin spinning={loading} tip="Loading...">
				<Card title="နေရပ် ပြင်ရန်" className="gs_mt">
					<div className="row">
						<div className="col-md-6">
							<p>အမျိုးအမည် ပြင်ရန်</p>
							<Input value={state} onChange={(txt) => setState(txt.target.value)} />
							<div className="row mt-3">
								<div className="col-md-12 text-right">
									<Link to="/item">
										<Button className="mr-3">Cancel</Button>
									</Link>
									<Button type="primary" onClick={updateAddress}>
										Update
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</Spin>
		</Drawer>
	);
};

export default ItemEdit;
