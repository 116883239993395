import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

function fetchItems(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/items`,
    data: payload
  }).then(data => handleResponse(data.data));
}

function detailItem(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/items/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

function createItem(payload) {
  return axios({
    method: "POST",
    url: `${apiEndpoint}/items`,
    data: payload
  }).then(data => handleResponse(data.data));
}

function updateItem(payload) {
  return axios({
    method: 'PUT',
    url: `${apiEndpoint}/items/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function deleteItem(payload) {
  return axios({
    method: 'DELETE',
    url: `${apiEndpoint}/items/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}


export const itemServices = {
  fetchItems,
  detailItem,
  createItem,
  updateItem,
  deleteItem
};
