import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Card, Input, Button } from 'antd';
import { addressActions } from '../../actions/address';
import { Link } from 'react-router-dom';

const AddressEdit = (props) => {
	const dispatch = useDispatch();
	const [ state, setState ] = useState('');
	const data = useSelector((state) => state.address.singleAddress);
	const loading = useSelector((state) => state.address.loading);
	useEffect(
		() => {
			const { id } = props.match.params;
			dispatch(addressActions.detailAddress({ id }));
			setState(data.address);
		},
		[ data.address, dispatch, props.match.params ]
	);

	const updateAddress = () => {
		const payload = {
			id      : data.id,
			address : state
		};
		dispatch(addressActions.updateAddress(payload));
	};

	return (
		<Drawer>
			<Spin spinning={loading} tip="Fetching data...">
				<Card title="နေရပ် ပြင်ရန်" className="gs_mt">
					<div className="row">
						<div className="col-md-6">
							<p>နေရပ်</p>
							<Input value={state} onChange={(txt) => setState(txt.target.value)} />
							<div className="row mt-3">
								<div className="col-md-12 text-right">
									<Link to="/address">
										<Button className="mr-3">Cancel</Button>
									</Link>
									<Button type="primary" onClick={updateAddress}>
										Update
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</Spin>
		</Drawer>
	);
};

export default AddressEdit;
