import {
  FETCH_MORTGAGE_SUCCESS,
  FETCH_MORTGAGE_DETAIL_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  mortgages: [],
  loading: false,
  singleMortgage: {}
};

export function mortgages(state = initialState, action) {
  switch (action.type) {
    case FETCH_MORTGAGE_SUCCESS:
      return {
        ...state,
        mortgages: action.payload
      };
    case FETCH_MORTGAGE_DETAIL_SUCCESS:
      return {
        ...state,
        singleMortgage: action.payload
      };
    default:
      return state;
  }
}
