import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
};

const middleWareList = applyMiddleware(thunkMiddleware);

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  // persistedReducer,
  rootReducer,
  compose(
    middleWareList
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

// export const persistor = persistStore(store);
