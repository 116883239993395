import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

class salesPortal extends Component {
	componentDidMount() {
		const checkAuth = sessionStorage.getItem('auth');
	}

	render() {
		return (
			<div className="container p-5">
				<div className="row justify-content-md-center my-5 py-5">
					<div >
						<Link to="/subCategory" className="mr-2 col col-lg-2 p-4 bg-primary text-center text-white rounded">
							အရောင်း
						</Link>
					</div>
					<div >
						<Link to="/#" className="mr-2 col col-lg-2 p-4 bg-primary text-center text-white rounded">
							အပေါင်
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default salesPortal;
