import { combineReducers } from 'redux';
import { address } from '../reducers/address';
import { items } from '../reducers/item';
import { remarks } from '../reducers/remark';
import { mortgages } from '../reducers/mortgage';
import { goldprices } from '../reducers/goldprice';
import { categories } from '../reducers/category';
import { subCategories } from '../reducers/subCategory';
import { saleprices } from '../reducers/saleprice';
import { print } from '../reducers/print';
import { invoices } from '../reducers/printInvoice';

export default combineReducers({
  address,
  items,
  remarks,
  mortgages,
  goldprices,
  categories,
  saleprices,
  subCategories,
  print,
  invoices
});
