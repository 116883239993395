import {
  FETCH_REMARKS_REQUEST,
  FETCH_REMARKS_SUCCESS,
  FETCH_REMARKS_FAILURE,

  CREATE_REMARKS_SUCCESS,
  UPDATE_REMARK_SUCCESS,
  DETAIL_REMARK_SUCCESS,
  DETAIL_REMARK_REQUEST,
  DELETE_REMARK_SUCCESS,
  DETAIL_REMARK_FAILURE,
} from "../constants/actionTypes";
import { remarkServices } from "../services/remark";
import history from '../history';

function fetchRemarks() {
  function request(payload) {
    return { type: FETCH_REMARKS_REQUEST, payload }
  }
  function success(payload) {
    return { type: FETCH_REMARKS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_REMARKS_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true))
    remarkServices.fetchRemarks().then(payload => {
      dispatch(success(payload));
      dispatch(request(false))
    }).catch(err => dispatch(failure(err.message)))
  };
}

function detailRemark(data) {
  function request(payload) {
    return { type: DETAIL_REMARK_REQUEST, payload }
  }
  function success(payload) {
    return { type: DETAIL_REMARK_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: DETAIL_REMARK_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true))
    remarkServices.detailRemark(data).then(payload => {
      dispatch(success(payload));
      dispatch(request(false))
    }).catch(err => dispatch(failure(err.message)))
  };
}

function createRemark(data) {
  function success(payload) {
    return { type: CREATE_REMARKS_SUCCESS, payload };
  }
  return dispatch => {
    remarkServices.createRemarks(data).then(payload => {
      dispatch(success(payload));
      history.push('/remark');
    });
  };
}

function updateRemark(data) {
  function success(payload) {
    return { type: UPDATE_REMARK_SUCCESS, payload };
  }
  return dispatch => {
    remarkServices.updateRemark(data).then(payload => {
      dispatch(success(payload));
      history.push('/remark');
    });
  };
}

function deleteRemark(data) {
  function success(payload) {
    return { type: DELETE_REMARK_SUCCESS, payload }
  }
  function request(payload) {
    return { type: FETCH_REMARKS_REQUEST, payload }
  }
  function getAllRemarks(payload) {
    return { type: FETCH_REMARKS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_REMARKS_FAILURE, payload }
  }
  return dispatch => {
    remarkServices.deleteRemark(data)
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(true))
        remarkServices.fetchRemarks().then(payload => {
          dispatch(getAllRemarks(payload));
          dispatch(request(false))
        }).catch(err => dispatch(failure(err.message)))
      })
  }
}


export const remarkActions = {
  fetchRemarks,
  detailRemark,
  createRemark,
  updateRemark,
  deleteRemark
};
