import React from "react";
import { Link } from "react-router-dom";

const RemarkList = () => {
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-7">
          <Link to="/#" className="p-4 text-white bg-secondary">
            salesitem name
          </Link>
        </div>
        <div className="col-md-5"></div>
      </div>
    </div>
  );
};

export default RemarkList;
