import React from 'react';
import { connect } from 'react-redux';
import { printInvoiceActions } from '../../actions/printInvoice';
import Pdf from 'react-to-pdf';

class ComponentToPrint extends React.Component {
	componentDidMount() {
		this.props.getPrintInvoice({ id: this.props.match.params.id });
		setTimeout(() => {
			this.getCaculate();
		}, 3000);
	}
	state = {
		display     : 'block',
		net_kyat    : 0,
		net_pal     : 0,
		net_yway    : 0,
		net_price   : 0,
		final_price : 0
	};
	constructor(props) {
		super(props);
		this.state = {
			date : new Date().toLocaleString()
		};
	}

	getCaculate = () => {
		const invoice = this.props.invoice ? this.props.invoice : null;
		const item = this.props.invoice.ayault && this.props.invoice.ayault.replace(/-/g, '').split('');
		console.log(this.props.invoice);
		let yway = parseInt(invoice.yway) + parseInt(item && item[2]);
		// let yway = invoice.yway + item && item[2];

		let pal = parseInt(invoice.pal) + parseInt(item && item[1]);
		let kyat_thar = parseInt(invoice.kyat_thar) + parseInt(item && item[0]);

		console.log(yway);

		if (yway >= 8) {
			pal += yway / 8;
		}

		yway %= 8;
		if (pal >= 16) {
			kyat_thar += pal / 16;
		}
		pal %= 16;
		let final_yway = (Math.floor(kyat_thar) * 128 + Math.floor(pal) * 8 + Math.floor(yway)) / 128;
		const net_price = final_yway;

		this.setState({
			net_kyat    : Math.floor(kyat_thar),
			net_pal     : pal,
			net_yway    : yway,
			net_price,
			final_price : final_yway
		});
	};

	onPrint = () => {};

	render() {
		const ref = React.createRef();
		const options = {
			orientation: 'portrait',
			unit        : 'in',
			format: [2200, 2700]
		};
		const invoice = this.props.invoice ? this.props.invoice : null;
		const item = this.props.invoice.ayault && this.props.invoice.ayault.replace(/-/g, '').split('');
		const { net_kyat, net_pal, net_yway } = this.state;
		return (
			<div className="container mt-2">
				<div ref={ref}>
					<div className="row">
						<div className="col-md-12">
							<h6 className="text-center">
								ဦးခင်မောင်ကြည် + ဇနီး သုဓမ္မသိင်္ဂီ ၊ သီဟသုဓမ္မသိင်္ဂီဒေါ်ကျင်မြင့် နှင့် သားများ
							</h6>
							<h3 className="text-center">မြကျင်ကျင်</h3>
							<h5 className="text-center">စိန်ရွှေရတနာဆိုင်</h5>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-md-6">
							တရုတ်တန်းလမ်း ၊ သုံးဆယ်မြို့။ <br />
							055-2230099 <br />
							09-5010596, 09-965010596
						</div>
						<div className="col-md-6 text-right">
							ခြံအမှတ် ၊ SH-3A/B ၊ မြကန်သာအိမ်ရာ ၊ ယုဇနလမ်း၊ (၅) ရပ်ကွက် ၊ လှိုင်မြို့နယ်၊ ရန်ကုန်မြို့။{' '}
							<br />
							09-5010596,09-5121821
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-md-4">ထုတ်ပေးသူ - {invoice.name}</div>
						<div className="col-md-4">ရွှေဈေးနှုန်း - {invoice.gprice}</div>
						<div className="col-md-4">နေ့စွဲ - {this.state.date}</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-md-6">
							<table className="table table-bordered">
								<tr>
									<th>ဘောင်ချာအမှတ်</th>
									<th>{invoice.vouchercode}</th>
								</tr>
								<tr>
									<th>ဝယ်ယူသူ အမည်</th>
									<th>{invoice.customerName}</th>
								</tr>
								<tr>
									<th>ဝယ်ယူသည့် ဖုန်းနံပါတ်</th>
									<th colspan="4">{invoice.customerphone} </th>
								</tr>
								<tr>
									<th>ဝယ်ယူသည့် လိပ်စာ</th>
									<th colspan="4">{invoice.customeraddress}</th>
								</tr>
								<tr>
									<th>ပစ္စည်းအမည်</th>
									<th>{invoice.item_name}</th>
								</tr>
								<tr>
									<th>ကုဒ်နံပါတ်</th>
									<th>{invoice.item_code}</th>
								</tr>
								<tr>
									<th>ရွှေရည် အမျိုးအစား</th>
									<th>{invoice.gname}</th>
								</tr>
							</table>
						</div>

						<div className="col-md-6">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th colspan="3">အကြောင်းအရာ</th>
										<th>ကျပ်</th>
										<th>ပဲ</th>
										<th>ရွှေး</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											အသားတင်ရွှေချိန်
										</th>
										<th>{invoice.kyat_thar}</th>
										<th>{invoice.pal}</th>
										<th>{invoice.yway}</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											အလျော့
										</th>
										<th>{item && item[0]}</th>
										<th>{item && item[1]}</th>
										<th>{item && item[2]}</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											စုစုပေါင်းအချိန်
										</th>
										<th>{net_kyat}</th>
										<th>{Math.floor(net_pal)}</th>
										<th>{net_yway}</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											ကျောက်တန်ဖိုး
										</th>
										<th colspan="3" className="text-right">
											{invoice.stonevalue}
										</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											ကျသင့်ငွေ
										</th>
										<th colspan="3" className="text-right">
											{Math.round(invoice.totalamount / 1000) * 1000}
										</th>
									</tr>
									<tr>
										<th colspan="3" className="text-right">
											Discount
										</th>
										<th colspan="3" className="text-right">
											<input
												type="text"
												name="discount"
												id=""
												className="form-control text-right"
											/>
										</th>
									</tr>
								</thead>
							</table>
						</div>
					</div>
					<div className="row">
						<div className="col-12 bg-light rounded p-1">
							<ul>
								<li>ရွှေထည်ပစ္စည်းများကို ရွှေရည်ပြည့်မှီကြောင်းအာမခံပါသည်။</li>
								<li>
									ကျောက်ထည်ပါ ပစ္စည်းများကို ကျောက်ချိန်နှုတ်ပြီး အသားတင်ရွှေချိန်ဖြင့်သာ
									ဘောက်ချာအတွင်းဖော်ပြပေးထားပါသည်။
								</li>
								<li>
									ရွှေထည်များကို အလျော့တွက်ယူလျှင် လက်ခမယူ၊ လက်ခယူလျှင် အလျော့တွက်မယူ စံနှုန်းဖြင့်
									သတ်မှတ်ထားပါသည်။
								</li>
								<li>
									စိန်ထည်များကို ပြန်လဲလျှင် (၄%) ပြန်သွင်းလျှင် (၈%) နှုန်းထားဖြင့် လက်ခံပေးပါသည်။
								</li>
								<li>
									အထည်များကို ပြန်လည်ရောင်းချလိုလျှင် ဘောက်ချာ တပါတည်း ယူဆောင်လာပေးပါရန်
									မေတ္တာရပ်ခံပါသည်။{' '}
								</li>
								<li>ဝယ်ယူအားပေးကြသော မိတ်ဆွေအပေါင်း ကိုယ်စိတ်နှစ်ဖြာ ကျန်းမာချမ်းသာကြပါစေ။</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<Pdf targetRef={ref} filename={invoice.vouchercode} options={options} x={9} y={0.5}>
						{({ toPdf }) => <button className="btn btn-info btn-block btn-sm" onClick={toPdf}>Generate pdf</button>}
					</Pdf>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ invoices }) => {
	return {
		invoice : invoices.invoice
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPrintInvoice : (data) => dispatch(printInvoiceActions.printInvoice(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentToPrint);
