import React, { useState } from "react";
import Drawer from "../../components/Drawer";
import { useDispatch } from "react-redux";
import { Card, Button, Input } from "antd";
import { remarkActions } from '../../actions/remark';
import { Link } from "react-router-dom";

const RemarkAdd = () => {
  const [state, setState] = useState("");
  const dispatch = useDispatch();
  const onCreateAddress = e => {
    dispatch(remarkActions.createRemark({ remark: state }));
  };
  return (
    <Drawer>
      <Card title="မှတ်ချက် အသစ်ထည့်ရန်">
        <div className="row">
          <div className="col-md-6">
            <p>မှတ်ချက်</p>
            <Input value={state} onChange={txt => setState(txt.target.value)} />
            <div className="mt-3 row">
              <div className="col-md-12 text-right">
                <Link to="/remark">
                  <Button className="mr-3">Cancel</Button>
                </Link>
                <Button type="primary" onClick={onCreateAddress}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Drawer>
  );
};

export default RemarkAdd;
