import { PRINT_INVOICE_SUCCESS } from '../constants/actionTypes';
import { printInvoiceServices } from '../services/printInvoice';
import history from '../history';

function printInvoice(data) {
  function success(payload) {
    return {
      type: PRINT_INVOICE_SUCCESS,
      payload,
    };
  }
  return dispatch => {
    printInvoiceServices.printInvoice(data).then(payload => {
      dispatch(success(payload));
    });
  };
}

export const printInvoiceActions = {
  printInvoice
}