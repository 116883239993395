import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { printActions } from "../../actions/print";
import { Input, Button } from "antd";

class saleLogin extends Component {
  state = {
    code: "",
    auth: false
  };
  componentDidMount() {
    const checkAuth = sessionStorage.getItem("auth");
    if (checkAuth == 1) {
      this.setState({ auth: true });
    }
  }

  onFormSubmit = e => {
    const { code } = this.state;
    this.props.sendCode({ code });
    this.setState({ code: '' });
    console.log(this.props.status)
    if(this.props.status) {
      this.setState({ auth: true });
    }
  };
  render() {
    if (this.state.auth) {
      return <Redirect to="/salesPortal" />;
    }

    const { code } = this.state;
    return (
      <div className="container p-5">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <Input
              placeholder="Enter your code"
              value={code}
              onChange={evt => this.setState({ code: evt.target.value })}
            />
          </div>
          <div className="col-md-3" />
        </div>

        <div className="row mt-2">
          <div className="col-md-3" />
          <div className="col-md-6">
            <Button
              type="primary"
              className="btn-block"
              onClick={this.onFormSubmit}
              disabled={!code}
            >
              Submit
            </Button>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ print }) => {
  return {
    status: print.status
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendCode: data => dispatch(printActions.sendCode(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(saleLogin);
