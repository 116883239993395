import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

function fetchRemarks(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/remarks`,
    data: payload
  }).then(data => handleResponse(data.data))
}

function detailRemark(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/remarks/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function createRemarks(payload) {
  return axios({
    method: 'POST',
    url: `${apiEndpoint}/remarks`,
    data: payload
  }).then(data => handleResponse(data.data))
}

function updateRemark(payload) {
  return axios({
    method: 'PUT',
    url: `${apiEndpoint}/remarks/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function deleteRemark(payload) {
  return axios({
    method: 'DELETE',
    url: `${apiEndpoint}/remarks/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

export const remarkServices = {
  fetchRemarks,
  detailRemark,
  createRemarks,
  updateRemark,
  deleteRemark,
}
