import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../components/Drawer";
import { Spin, Card, Button, Table, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { salePriceActions } from "../../actions/saleprice";

const SaleItemsList = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.saleprices);
  const loading = useSelector(state => state.saleprices.loading);

  useEffect(() => {
    dispatch(salePriceActions.fetchSalePrice());
  }, []);

  const onSalePriceDelete = id => {
    dispatch(salePriceActions.deleteSalePrice({ id }));
  };

  const columns = [
    {
      title: "စဉ်",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "ကုဒ်နံပါတ်",
      dataIndex: "item_code",
      key: "item_code"
    },
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "item_name",
      key: "item_name"
    },
    {
      title: "ကျပ်",
      dataIndex: "kyat_thar",
      key: "kyat_thar"
    },
    {
      title: "ပဲ",
      dataIndex: "pal",
      key: "pal"
    },
    {
      title: "ရွေး",
      dataIndex: "yway",
      key: "yway"
    },
    {
      title: "ပစ္စည်း ဈေးနှုန်း",
      dataIndex: "price",
      key: "price"
    },
    {
      title: "အရေအတွက်",
      dataIndex: "qty",
      key: "qty"
    },
    {
      title: "အလျော့",
      dataIndex: "ayault",
      key: "ayault"
    },
    {
      title: "ကျောက်တန်ဖိုး",
      dataIndex: "stonevalue",
      key: "stonevalue"
    },
    {
      title: "ရွှေစျေးနှုန်း",
      dataIndex: "goldprice.name",
      key: "goldprice.name",
      render: (text, record) => {
        return <span>{record.goldprice.name}</span>
      }
    },
    {
      title: "မှတ်ချက်",
      dataIndex: "remark",
      key: "remark"
    },
    {
      title: "လုပ်ဆောင်ချက်",
      render: (text, record) => {
        return (
          <React.Fragment>
            <Link to={`/saleitem/${record.id}`}>
              <Button type="primary"> ပြင်ရန် </Button>
            </Link>
          </React.Fragment>
        );
      }
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Popconfirm
            title={`Are you sure delete this ${record.item_code}?`}
            onConfirm={() => onSalePriceDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              ဖျက်ရန်
            </Button>
          </Popconfirm>
        );
      }
    }
  ];
  return (
    <Drawer>
      <Spin spinning={loading} tip="Loading...">
        <Card
          title="ပစ္စည်းစာရင်း"
          extra={
            <Link to="/saleitemAdd">
              <Button type="primary"> အသစ်ထည့်ရန် </Button>
            </Link>
          }
        >
          <Table
            dataSource={state.salepriceList}
            columns={columns}
            rowKey="id"
          />
        </Card>
      </Spin>
    </Drawer>
  );
};

export default SaleItemsList;
