import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../../components/Drawer';
import { Card, Input, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { salePriceActions } from '../../actions/saleprice';
import { goldpriceActions } from '../../actions/goldprice';

const SaleItemsAdd = () => {
	const dispatch = useDispatch();
	const goldprices = useSelector((state) => state.goldprices.goldPrices);
	useEffect(
		() => {
			dispatch(goldpriceActions.fetchGoldPrices());
		},
		[ dispatch ]
	);
	const [ state, setState ] = useState({
		code        : '',
		item_name   : '',
		kyat_thar   : 0,
		pal         : 0,
		yway        : 0,
		price       : 0,
		qty         : 0,
		ayault      : 0,
		stonevalue  : 0,
		goldprice_id : 0,
		remark      : ''
	});

	const onInputChange = (evt) => {
		setState({ ...state, [evt.target.name]: evt.target.value });
  };
  
  const onGoldPriceChange = evt => {
    console.log(evt.target.value);
    setState({ ...state, goldprice_id: evt.target.value });
  };

	const onFormSubmit = (evt) => {
		dispatch(salePriceActions.createSalePrice(state));
	};

	return (
		<Drawer>
			<Card title="ပစ္စည်းထည့်ရန်">
				<div className="row mb-3">
					<div className="col-md-3">
						ကုဒ်နံပါတ် <br />
						<Input name="code" value={state.code} onChange={onInputChange} placeholder="Item Code" />
					</div>
					<div className="col-md-3">
						ပစ္စည်းအမည် <br />
						<Input
							name="item_name"
							value={state.item_name}
							onChange={onInputChange}
							placeholder="Item Name"
						/>
					</div>
					<div className="col-md-3">
						ကျပ် <br />
						<Input name="kyat_thar" onChange={onInputChange} placeholder="Kyat thar" />
					</div>
					<div className="col-md-3">
						ပဲ <br />
						<Input name="pal" onChange={onInputChange} placeholder="Pal" />
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-md-3">
						ရွေး <br />
						<Input name="yway" onChange={onInputChange} placeholder="Yway" />
					</div>
					<div className="col-md-3">
						ဈေးနှုန်း <br />
						<Input name="price" onChange={onInputChange} placeholder="Price" />
					</div>
					<div className="col-md-3">
						အရေအတွက် <br />
						<Input name="qty" onChange={onInputChange} placeholder="Qty" />
					</div>
					<div className="col-md-3">
						အလျော့ (ကျပ်-ပဲ-ရွေး) <br />
						<Input name="ayault" onChange={onInputChange} placeholder="ayault" />
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-md-3">
						ကျောက်တန်ဖိုး <br />
						<Input name="stonevalue" onChange={onInputChange} placeholder="stonevalue" />
					</div>
					<div className="col-md-3">
						ရွှေစျေးနှုန်း <br />
						<Radio.Group onChange={onGoldPriceChange} buttonStyle="solid">
							{goldprices.map(({ id, name, price }) => (
								<Radio.Button value={id} key={id}>
									{name}
								</Radio.Button>
							))}
						</Radio.Group>
					</div>
					<div className="col-md-6">
						မှတ်ချက် <br />
						<Input name="remark" onChange={onInputChange} placeholder="remark" />
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-md-6" />
					<div className="col-md-6 text-right">
						<Link to="/saleitems" className="pr-3">
							<Button>Cancel</Button>
						</Link>
						<Button type="primary" onClick={onFormSubmit}>
							Save
						</Button>
					</div>
				</div>
			</Card>
		</Drawer>
	);
};

export default SaleItemsAdd;
