import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

function fetchGoldPrices(payload) {
    return axios({
        method: "GET",
        url: `${apiEndpoint}/goldprice`,
        data: payload
    }).then(data => handleResponse(data.data));
}

function detailGoldPrice(payload) {
    return axios({
        method: "GET",
        url: `${apiEndpoint}/goldprice/${payload.id}`,
        data: payload
    }).then(data => handleResponse(data.data));
}

function createGoldPrice(payload) {
    return axios({
        method: "POST",
        url: `${apiEndpoint}/goldprice`,
        data: payload
    }).then(data => handleResponse(data.data));
}

function updateGoldPrice(payload) {
    return axios({
        method: 'PUT',
        url: `${apiEndpoint}/goldprice/${payload.id}`,
        data: payload
    }).then(data => handleResponse(data.data));
}

async function deleteGoldPrice(payload) {
    return axios({
        method: 'DELETE',
        url: `${apiEndpoint}/goldprice/${payload.id}`,
        data: payload
    }).then(data => handleResponse(data.data))
}


export const goldPriceServices = {
    fetchGoldPrices,
    detailGoldPrice,
    createGoldPrice,
    updateGoldPrice,
    deleteGoldPrice
};
