import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

function createPrintVoucher(payload) {
  return axios({
    method: 'POST',
    url: `${apiEndpoint}/printVoucher`,
    data: payload
  }).then(data => handleResponse(data.data))
};

function fetchPrintVoucher(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/printVoucher`,
    data: payload
  }).then(data => handleResponse(data.data))
};


function sendCode(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/salesroute/${payload.code}`,
    data: payload
  }).then(data => handleResponse(data.data))
};


export const printServices = {
  fetchPrintVoucher,
  createPrintVoucher,
  sendCode
}