import { apiEndpoint } from '../config/api';
import { handleResponse } from '../config/handleResponse';
import axios from 'axios';

function fetchAllCategories(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/category`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function detailCategory(payload) {
  const data = await axios({
    method: 'GET',
    url: `${apiEndpoint}/category/${payload}`,
    data: payload
  });
  return handleResponse(data.data);
}

async function createCategory(payload) {
  return axios({
    method: 'POST',
    url: `${apiEndpoint}/category`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function updateCategory(payload) {
  return axios({
    method: 'PUT',
    url: `${apiEndpoint}/category/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function deleteCategory(payload) {
  return axios({
    method: 'DELETE',
    url: `${apiEndpoint}/category/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

export const categoryServices = {
  fetchAllCategories,
  detailCategory,
  createCategory,
  updateCategory,
  deleteCategory
}