import { apiEndpoint } from '../config/api';
import { handleResponse } from '../config/handleResponse';
import axios from 'axios';

async function fetchAddress(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/address`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function detailAddress(payload) {
  const data = await axios({
    method: 'GET',
    url: `${apiEndpoint}/address/${payload}`,
    data: payload
  });
  return handleResponse(data.data);
}

async function createAddress(payload) {
  return axios({
    method: 'POST',
    url: `${apiEndpoint}/address`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function updateAddress(payload) {
  return axios({
    method: 'PUT',
    url: `${apiEndpoint}/address/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

async function deleteAddress(payload) {
  return axios({
    method: 'DELETE',
    url: `${apiEndpoint}/address/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}



export const addressServices = {
  fetchAddress,
  detailAddress,
  createAddress,
  updateAddress,
  deleteAddress
}