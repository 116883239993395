import {
  FETCH_SALES_PORTAL_SUCCESS
} from "../constants/actionTypes";
import { salesPortalServies } from "../services/salesPortal";

function fetchSalesPrice() {
  function success(payload) {
    return { type: FETCH_SALES_PORTAL_SUCCESS, payload };
  }
  return dispatch => {
    salesPortalServies.fetchSalesPortal().then(payload => {
      dispatch(success(payload));
    });
  };
}

export const salesPortalActions = {
  fetchSalesPrice
}