import React, { useEffect } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Card, Button, Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { remarkActions } from '../../actions/remark';

const RemarkList = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.remarks);
	const loading = useSelector((state) => state.remarks.loading);
	useEffect(
		() => {
			dispatch(remarkActions.fetchRemarks());
		},
		[ dispatch ]
	);

	const onRemarkDelete = (id) => {
		dispatch(remarkActions.deleteRemark({ id }));
	};

	const columns = [
		{
			title     : 'နံပါတ်',
			dataIndex : 'id',
			key       : 'id'
		},
		{
			title     : 'မှတ်ချက်',
			dataIndex : 'remark',
			key       : 'remark'
		},
		{
			title  : 'လုပ်ဆောင်ချက်',
			render : (text, record) => {
				return (
					<Link to={`/remark/${record.id}`}>
						<Button type="primary"> ပြင်ရန် </Button>
					</Link>
				);
			}
		},
		{
			title  : '',
			render : (text, record) => {
				return (
          <Popconfirm
          title={`Are you sure delete this ${record.remark}?`}
          onConfirm={() => onRemarkDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
					<Button type="primary" danger>
						ဖျက်ရန်
					</Button>
          </Popconfirm>
				);
			}
		}
	];

	return (
		<Drawer>
			<Spin spinning={loading} tip="Loading...">
				<Card
					title="မှတ်ချက် စာရင်း"
					extra={
						<Link to="/remarkAdd">
							<Button type="primary"> အသစ်ထည့်ရန် </Button>
						</Link>
					}
				>
					<Table dataSource={state.remarks} columns={columns} rowKey="id" />
				</Card>
			</Spin>
		</Drawer>
	);
};

export default RemarkList;
