import {
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  DETAIL_CATEGORY_FAILURE,
  DETAIL_CATEGORY_REQUEST,
  DETAIL_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS
} from "../constants/actionTypes";
import { categoryServices } from "../services/category";
import history from "../history";

function getAllCategories(data) {
  function request(payload) {
    return { type: FETCH_CATEGORY_REQUEST, payload };
  }
  function success(payload) {
    return { type: FETCH_CATEGORY_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_CATEGORY_FAILURE, payload };
  }
  return dispatch => {
    dispatch(request(true));
    categoryServices
      .fetchAllCategories(data)
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(false));
      })
      .catch(err => dispatch(failure(err.message)));
  };
}

function detailCategory(data) {
  function request(payload) {
    return { type: DETAIL_CATEGORY_REQUEST, payload };
  }
  function success(payload) {
    return { type: DETAIL_CATEGORY_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: DETAIL_CATEGORY_FAILURE, payload };
  }
  return dispatch => {
    dispatch(request(true));
    categoryServices
      .detailCategory(data.id)
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(false));
      })
      .catch(err => dispatch(failure(err.message)));
  };
}

function createCategories(data) {
  function request(payload) {
    return { type: CREATE_CATEGORY_REQUEST, payload };
  }
  function success(payload) {
    return { type: CREATE_CATEGORY_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: CREATE_CATEGORY_FAILURE, payload };
  }
  return dispatch => {
    dispatch(request(true));
    categoryServices
      .createCategory(data)
      .then(payload => {
        dispatch(success(payload));
        history.push("/categories");
        dispatch(request(false));
      })
      .catch(err => dispatch(failure(err.message)));
  };
}

function updateCategory(data) {
  function success(payload) {
    return { type: UPDATE_CATEGORY_SUCCESS, payload };
  }
  return dispatch => {
    categoryServices.updateCategory(data).then(payload => {
      dispatch(success(payload));
      history.push("/categories");
    });
  };
}

function deleteCategory(data) {
  function success(payload) {
    return { type: DELETE_CATEGORY_SUCCESS, payload };
  }
  function request(payload) {
    return { type: FETCH_CATEGORY_REQUEST, payload };
  }
  function getAllCategories(payload) {
    return { type: FETCH_CATEGORY_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_CATEGORY_FAILURE, payload };
  }
  return dispatch => {
    categoryServices.deleteCategory(data).then(payload => {
      dispatch(success(payload));
      dispatch(request(true));
      categoryServices
        .fetchAllCategories(data)
        .then(payload => {
          dispatch(getAllCategories(payload));
          dispatch(request(false));
        })
        .catch(err => dispatch(failure(err.message)));
    });
  };
}

export const categoryActions = {
  getAllCategories,
  detailCategory,
  createCategories,
  updateCategory,
  deleteCategory
};
