import {
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
  FETCH_ADDRESS_FAILURE,

  CREATE_ADDRESS_SUCCESS,

  UPDATE_ADDRESS_SUCCESS,

  DETAIL_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DETAIL_ADDRESS_FAILURE,

  DETAIL_ADDRESS_SUCCESS,
} from '../constants/actionTypes';
import { addressServices } from '../services/address';
import history from '../history';

function fetchAddress() {
  function request(payload) {
    return { type: FETCH_ADDRESS_REQUEST, payload }
  };
  function success(payload) {
    return { type: FETCH_ADDRESS_SUCCESS, payload }
  };
  function failure(payload) {
    return { type: FETCH_ADDRESS_FAILURE, payload }
  };
  return dispatch => {
    dispatch(request(true))
    addressServices.fetchAddress()
      .then(payload => {
        dispatch(success(payload))
        dispatch(request(false))
      }).catch(err => dispatch(failure(err.message)))
  }
}

function detailAddress(data) {
  function request(payload) {
    return { type: DETAIL_ADDRESS_REQUEST, payload }
  }
  function success(payload) {
    return { type: DETAIL_ADDRESS_SUCCESS, payload }
  }
  function failure(payload) {
    return { type: DETAIL_ADDRESS_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true));
    addressServices.detailAddress(data.id)
      .then(payload => {
        dispatch(success(payload))
        dispatch(request(false));
      }).catch(err => dispatch(failure(err.message)))
  }
}

function createAddress(data) {
  function success(payload) {
    return { type: CREATE_ADDRESS_SUCCESS, payload }
  }
  return dispatch => {
    addressServices.createAddress(data)
      .then(payload => {
        dispatch(success(payload))
        history.push('/address')
      })
  }
}

function updateAddress(data) {
  function success(payload) {
    return { type: UPDATE_ADDRESS_SUCCESS, payload }
  }
  return dispatch => {
    addressServices.updateAddress(data)
      .then(payload => {
        dispatch(success(payload))
        history.push('/address')
      })
  }
}

function deleteAddress(data) {
  function success(payload) {
    return { type: DELETE_ADDRESS_SUCCESS, payload }
  }
  function request(payload) {
    return { type: FETCH_ADDRESS_REQUEST, payload }
  };
  function getAllAddress(payload) {
    return { type: FETCH_ADDRESS_SUCCESS, payload }
  };
  return dispatch => {
    addressServices.deleteAddress(data)
      .then(payload => {
        dispatch(success(payload))
        dispatch(request(true))
        addressServices.fetchAddress()
          .then(payload => {
            dispatch(getAllAddress(payload))
            dispatch(request(false))
          })
      })
  }
}

export const addressActions = {
  fetchAddress,
  detailAddress,
  createAddress,
  updateAddress,
  deleteAddress
}
