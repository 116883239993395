import {
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_FAILURE,
  DETAIL_CATEGORY_SUCCESS,
  DETAIL_CATEGORY_REQUEST,
  DETAIL_CATEGORY_FAILURE
} from "../constants/actionTypes";

const initialState = {
  categories: [],
  loading: false,
  singleCategory: {}
};

export function categories(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        categories: []
      };
    case DETAIL_CATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload
      };
    case DETAIL_CATEGORY_SUCCESS:
      return {
        ...state,
        singleCategory: action.payload
      };
    case DETAIL_CATEGORY_FAILURE:
      return {
        ...state,
        singleCategory: {},
        loading: false
      };
    default:
      return state;
  }
}
