import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { DashboardTwoTone, UserSwitchOutlined, DatabaseTwoTone, SettingTwoTone, NotificationTwoTone, HeatMapOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import nav from './_nav';
import { Link } from 'react-router-dom';

const { Header, Sider } = Layout;

const Drawer = (props) => {
	const [collapsed, setCollapsed] = useState(false);

	const trigger = () => {
		setCollapsed(!collapsed);
	};

	const renderIcons = (iconName) => {
		switch (iconName) {
			case 'Dashboard': return <Icon component={DashboardTwoTone} />;
			case 'users': return <Icon component={UserSwitchOutlined} />;
			case 'အပေါင် လက်ခံ': return <Icon component={DatabaseTwoTone} />;
			case 'နေရပ်': return <Icon component={HeatMapOutlined} />;
			case 'အမျိုးအမည်': return <Icon component={SettingTwoTone} />;
			case 'မှတ်ချက်': return <Icon component={NotificationTwoTone} />;
			case 'ရွေစျေးနှုန်း': return <Icon component={NotificationTwoTone} />;
			default: return;
		}
	}

	return (
		<Layout style={{ height: '100vh' }} >
			<Sider trigger={null} breakpoint="lg" collapsedWidth="0" collapsible collapsed={collapsed}>
				<div className="logo" />
				<Menu theme="dark" mode="inline">
					{nav.items.map((item, index) => {
						return (
							<Menu.Item key={index}>
								<Link to={item.route} className="href">
									{renderIcons(item.name)}
									<span>{item.name}</span>
								</Link>
							</Menu.Item>
						);
					})}
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Header className="site-layout-background" style={{ padding: 0 }}>
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: trigger
					})}
				</Header>
				<div style={{ margin: 24 }}>
					{props.children}
				</div>
			</Layout>
		</Layout>
	);
};

export default Drawer;
