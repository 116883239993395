import {
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,

  CREATE_ITEMS_SUCCESS,
  ITEM_DETAIL_REQUEST,
  ITEM_DETAIL_SUCCESS,
  ITEM_DETAIL_FAILURE,
  UPDATE_ITEM_SUCCESS,
  DELETE_ITEM_SUCCESS
} from "../constants/actionTypes";
import { itemServices } from "../services/item";
import history from '../history';

function fetchItems() {
  function request(payload) {
    return { type: FETCH_ITEMS_REQUEST, payload }
  }
  function success(payload) {
    return { type: FETCH_ITEMS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_ITEMS_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true));
    itemServices.fetchItems().then(payload => {
      dispatch(success(payload));
      dispatch(request(false));
    }).catch(err => dispatch(failure(err.message)))
  };
}

function detailItem(data) {
  function request(payload) {
    return { type: ITEM_DETAIL_REQUEST, payload }
  }
  function success(payload) {
    return { type: ITEM_DETAIL_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: ITEM_DETAIL_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true));
    itemServices.detailItem(data).then(payload => {
      dispatch(success(payload));
      dispatch(request(false));
    }).catch(err => dispatch(failure(err.message)))
  };
}

function createItem(data) {
  function success(payload) {
    return { type: CREATE_ITEMS_SUCCESS, payload };
  }
  return dispatch => {
    itemServices.createItem(data).then(payload => {
      dispatch(success(payload));
      history.push('/item')
    });
  };
}

function updateItem(data) {
  function success(payload) {
    return { type: UPDATE_ITEM_SUCCESS, payload }
  }
  return dispatch => {
    itemServices.updateItem(data)
      .then(payload => {
        dispatch(success(payload));
        history.push('/item');
      })
  }
}

function deleteItem(data) {
  function success(payload) {
    return { type: DELETE_ITEM_SUCCESS, payload }
  }
  function request(payload) {
    return { type: FETCH_ITEMS_REQUEST, payload }
  }
  function getAllItems(payload) {
    return { type: FETCH_ITEMS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_ITEMS_FAILURE, payload }
  }
  return dispatch => {
    itemServices.deleteItem(data)
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(true));
        itemServices.fetchItems().then(payload => {
          dispatch(getAllItems(payload));
          dispatch(request(false));
        }).catch(err => dispatch(failure(err.message)))
      })
  }
}

export const itemActions = {
  fetchItems,
  detailItem,
  createItem,
  updateItem,
  deleteItem
};
