import React, { Component } from 'react'
import { connect } from 'react-redux';
import { printActions } from '../../actions/print';
import { Link } from 'react-router-dom';
class printInvoice extends Component {
  componentDidMount() {
    this.props.getPrintedInvoice();
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <table className="table table-bordered">
            <tr>
              <th>Invoice No</th>
              <th>Amount</th>
              <th>customerName</th>
              <th>Action</th>
            </tr>
            {this.props.data.map((item, index) => {
              return (
                <tr key={index}>
                  <th>{item.vouchercode}</th>
                  <th>{Math.round(item.totalamount / 1000) * 1000}</th>
                  <th>{item.customerName}</th>
                  {item.inv_status === 0
                    ? (
                      <th>
                        <Link to={`/print/${item.id}`} className="btn btn-primary">ဘောင်ချာထုတ်ရန်</Link>
                      </th>
                    )
                    : <th></th>
                  }
                </tr>
              )
            })}

          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ print }) => {
  return {
    data: print.vouchers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPrintedInvoice: () => dispatch(printActions.fetchPrintVoucher())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(printInvoice);