import {
  PRINT_VOUCHER_SUCCESS,
  SAVE_CUSTOMERS_INFORMATION,
  SEND_CODE_SUCCESS,
  FETCH_PRINT_VOUCHER_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  customers: {},
  data: [],
  errorMsg: '',
  vouchers: [],
  status: null
};

export function print(state = initialState, action) {
  switch (action.type) {
    case SAVE_CUSTOMERS_INFORMATION:
      return {
        ...state,
        customers: action.payload,
        data: [],
      };
    case SEND_CODE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errorMsg: state.data == [] ?  null : 'Code is invalid',
        status: action.payload.length > 0 ? true : false
      }
    case FETCH_PRINT_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: action.payload
      }
    default:
      return state;
  }
}
