import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../../components/Drawer';
import { Spin, Card, Button, Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { itemActions } from '../../actions/item';

const ItemList = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.items);
	const loading = useSelector((state) => state.items.loading);

	useEffect(
		() => {
			dispatch(itemActions.fetchItems());
		},
		[ dispatch ]
	);

	const onItemDelete = (id) => {
		dispatch(itemActions.deleteItem({ id }));
	};

	const columns = [
		{
			title     : 'နံပါတ်',
			dataIndex : 'id',
			key       : 'id'
		},
		{
			title     : 'အမျိုးအမည်',
			dataIndex : 'name',
			key       : 'name'
		},
		{
			title  : 'လုပ်ဆောင်ချက်',
			render : (text, record) => {
				return (
					<Link to={`/item/${record.id}`}>
						<Button type="primary"> ပြင်ရန် </Button>
					</Link>
				);
			}
		},
		{
			title  : '',
			render : (text, record) => {
				return (
					<Popconfirm
						title={`Are you sure delete this ${record.name}?`}
						onConfirm={() => onItemDelete(record.id)}
						okText="Yes"
						cancelText="No"
					>
						<Button type="primary" danger>
							ဖျက်ရန်
						</Button>
					</Popconfirm>
				);
			}
		}
	];

	return (
		<Drawer>
			<Spin spinning={loading} tip="Loading...">
				<Card
					title="အမျိုးအမည် စာရင်း"
					extra={
						<Link to="/itemAdd">
							<Button type="primary"> အသစ်ထည့်ရန် </Button>
						</Link>
					}
				>
					<Table dataSource={state.items} columns={columns} rowKey="id" />
				</Card>
			</Spin>
		</Drawer>
	);
};

export default ItemList;
