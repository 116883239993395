import {
  FETCH_REMARKS_REQUEST,
  FETCH_REMARKS_SUCCESS,
  FETCH_REMARKS_FAILURE,
  // CREATE_REMARKS_SUCCESS,
  // UPDATE_REMARK_SUCCESS,
  DETAIL_REMARK_REQUEST,
  DETAIL_REMARK_SUCCESS,
  DETAIL_REMARK_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  remarks: [],
  detailRemark: {},
  loading: false,
};

export function remarks(state = initialState, action) {
  switch (action.type) {
    case FETCH_REMARKS_REQUEST:
      return {
        ...state,
        loading: action.payload
      }
    case FETCH_REMARKS_SUCCESS:
      return {
        ...state,
        remarks: action.payload
      };
    case FETCH_REMARKS_FAILURE:
      return {
        ...state,
        remarks: [],
        loading: false,
      }
    case DETAIL_REMARK_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case DETAIL_REMARK_SUCCESS:
      return {
        ...state,
        detailRemark: action.payload
      }
    case DETAIL_REMARK_FAILURE:
      return {
        ...state,
        loading: false,
        detailRemark: {}
      }
    default:
      return state;
  }
}
