import {
  FETCH_MORTGAGE_SUCCESS,
  FETCH_MORTGAGE_DETAIL_SUCCESS,
  ADD_MORTGAGE_SUCCESS,
  DELETE_MORTGAGE_SUCCESS,
  UDPATE_MORTGAGE,
} from "../constants/actionTypes";
import { mortgageServices } from "../services/mortgage";

function fetchMortgage() {
  function success(payload) {
    return { type: FETCH_MORTGAGE_SUCCESS, payload };
  }
  return dispatch => {
    mortgageServices.fetchMortgage().then(payload => {
      dispatch(success(payload));
    });
  };
}

function fetchMortgageDetail(data) {
  function success(payload) {
    return { type: FETCH_MORTGAGE_DETAIL_SUCCESS, payload };
  }
  return dispatch => {
    mortgageServices.mortgageDetail(data).then(payload => {
      dispatch(success(payload));
    });
  };
}

function addMortgage(data) {
  function success(payload) {
    return { type: ADD_MORTGAGE_SUCCESS, payload };
  }
  return dispatch => {
    mortgageServices.addMortgage(data).then(payload => {
      dispatch(success(payload));
    });
  };
}

function updateMortgage(data) {
  function success(payload) {
    return { type: UDPATE_MORTGAGE, payload };
  }
  return dispatch => {
    mortgageServices.updateMortgage(data).then(payload => {
      dispatch(success(payload))
    })
  }
}

function deleteMortgage(data) {
  function success(payload) {
    return { type: DELETE_MORTGAGE_SUCCESS, payload }

  }
  return dispatch => {
    mortgageServices.deleteMortgage(data).then(payload => {
      dispatch(success(payload))
    })
  }
}


export const mortgageActions = {
  fetchMortgage,
  fetchMortgageDetail,
  addMortgage,
  deleteMortgage,
  updateMortgage
};
