import React from 'react';
import Drawer from '../../components/Drawer';
import { connect } from 'react-redux';
import { Spin, Card, Input, Button } from 'antd';
import { goldpriceActions } from '../../actions/goldprice';
import { Link } from 'react-router-dom';

class GoldPriceEdit extends React.Component {
	state = {
		id: 0,
		name: '',
		price: 0,
	}

	componentDidMount() {
		this.props.getDetail({ id: this.props.match.params.id });
	}

	componentWillReceiveProps(prevProps) {
		const { goldprice } = prevProps;
		this.setState({
			id: goldprice.id,
			name: goldprice.name,
			price: goldprice.price
		})
	}

	onChange = evt => {
		this.setState({ [evt.target.name]: evt.target.value });
	}

	updateGoldPrice = e => {
		e.preventDefault();
		const payload = {
			id: this.state.id,
			name: this.state.name,
			price: this.state.price,
		};
		this.props.updateGoldPrice(payload);
	};

	render() {
		const { name, price } = this.state;
		const { loading } = this.props;
		return (
			<Drawer>
				<Spin spinning={loading} tip="Loading...">
					<Card title="ရွေစျေးနှုန်းစာရင်းပြင်ရန်" className="gs_mt">
						<div className="row">
							<div className="col-md-3">
								Name<br />
								<Input name="name" value={name} onChange={this.onChange} />
							</div>
							<div className="col-md-3">
								Price<br />
								<Input name="price" value={price} onChange={this.onChange} />
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-md-3">
								<Link to="/goldprice">
									<Button className="mr-3">Cancel</Button>
								</Link>
							</div>
							<div className="col-md-3">
								<Button type="primary" onClick={this.updateGoldPrice}>
									Update
								</Button>
							</div>
						</div>
					</Card>
				</Spin>
			</Drawer>
		);
	}
}

function mapStateToProps({ goldprices }) {
	return {
		goldprice : goldprices.singleGoldPrice,
		loading: goldprices.loading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDetail : (data) => dispatch(goldpriceActions.detailGoldPrice(data)),
		updateGoldPrice: data => dispatch(goldpriceActions.updateGoldPrice(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoldPriceEdit);
