import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { subCategoryActions } from '../../actions/subCategory';
import { salesPortalActions } from '../../actions/salesPortal';

class subCategory extends Component {
	constructor(props) {
		super();
	
	}
	componentDidMount() {
		this.props.getAllSalesPortal();
	}
	
	onMakeRequest = (id) => {
		this.props.getAllSubCategories({ id });
	};

	render() {
		const menu = this.menuItems;

		return (
			<div className="container-fluid pt-2">

				<div className="row">
					<div className="col-12">
						{this.props.salesPortals.map((item, index) => {
							return (
								<button className="btn btn-warning px-2 mr-1" style={{width:"100px"}} onClick={() => this.onMakeRequest(item.id)}>{item.name}</button>
							)
						})}
					</div>
				</div>
				<hr/>
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							{this.props.subs.map((item, index) => {
								return (
									<div className="col-2" key={index}>
										<div >
											<Link className="rounded btn btn-info p-3 mb-1 text-center text-white w-100" to={`/showItem/${item.id}`} style={{ color: '#fff' }}>{item.name}</Link>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDetail: (data) => dispatch(subCategoryActions.fetchSubCategories(data)),
		getAllSalesPortal: () => dispatch(salesPortalActions.fetchSalesPrice()),
		getAllSubCategories: (data) => dispatch(subCategoryActions.fetchSubCategories(data))
	};
};
const mapStateToProps = ({ subCategories }) => {
	return {
		subs: subCategories.subcategories,
		salesPortals: subCategories.salesPortals
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(subCategory);
