import React from 'react';
import Drawer from "../../components/Drawer";
import { Card } from 'antd';

const MortgageAddition = () => {
  return (
    <Drawer>
      <Card title="example"></Card>
    </Drawer>
  );
}

export default MortgageAddition;