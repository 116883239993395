import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

async function fetchMortgage(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/mortgage`,
    data: payload
  }).then(data => handleResponse(data.data))
}

function mortgageDetail(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/mortgage/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

function addMortgage(payload) {
  return axios({
    method: "POST",
    url: `${apiEndpoint}/mortgage`,
    data: payload
  }).then(data => handleResponse(data.data));
}

function updateMortgage(payload) {
  return axios({
    method: 'PUT',
    url: `${apiEndpoint}/mortgage/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))


}


function deleteMortgage(payload) {
  return axios({
    method: 'DELETE',
    url: `${apiEndpoint}/mortgage/${payload.id}`,
    data: payload

  }).then(data => handleResponse(data.data))
}
export const mortgageServices = {
  fetchMortgage,
  mortgageDetail,
  addMortgage,
  updateMortgage,
  deleteMortgage

};
