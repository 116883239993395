import React, { useEffect } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Table, Button, Card, Popconfirm } from 'antd';
import { addressActions } from '../../actions/address';
import { Link } from 'react-router-dom';

const AddressList = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.address);
	const loading = useSelector((state) => state.address.loading);
	useEffect(
		() => {
			dispatch(addressActions.fetchAddress());
		},
		[ dispatch ]
	);

	const onAddressDelete = (id) => {
		dispatch(addressActions.deleteAddress({ id }));
	};

	const columns = [
		{
			title     : 'နံပါတ်',
			dataIndex : 'id',
			key       : 'id'
		},
		{
			title     : 'နေရပ်',
			dataIndex : 'address',
			key       : 'address'
		},
		{
			title  : 'လုပ်ဆောင်ချက်',
			render : (text, record) => {
				return (
					<Link to={`/address/${record.id}`}>
						<Button type="primary"> ပြင်ရန် </Button>
					</Link>
				);
			}
		},
		{
			title  : '',
			render : (text, record) => {
				return (
					<Popconfirm
						title={`Are you sure delete this ${record.address}?`}
						onConfirm={() => onAddressDelete(record.id)}
						okText="Yes"
						cancelText="No"
					>
						<Button type="primary" danger>
							ဖျက်ရန်
						</Button>
					</Popconfirm>
				);
			}
		}
	];
	return (
		<Drawer>
			<Spin spinning={loading} tip="Loading...">
				<Card
					title="မှတ်ချက် စာရင်း"
					extra={
						<span>
							<Link to="/ads/create">
								<Button type="primary"> အသစ်ထည့်ရန် </Button>
							</Link>
						</span>
					}
				>
					<Table dataSource={state.addresses} columns={columns} rowKey="id" />
				</Card>
			</Spin>
		</Drawer>
	);
};

export default AddressList;
