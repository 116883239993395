import React from 'react';
import { Card } from 'antd';
import Drawer from "../../components/Drawer";

class Dashboard extends React.Component {
  render() {
    return (
      <Drawer>
        <Card>Login Success</Card>
      </Drawer>
    );
  }
}

export default Dashboard;
