import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch } from 'react-redux';
import { addressActions } from '../../actions/address';
import { Card, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

const AddressCreate = () => {
	const dispatch = useDispatch();
	const [state, setState] = useState('');
	const onCreateAddress = e => {
		dispatch(addressActions.createAddress({ address: state }))
	}
	return (
		<Drawer>
			<Card title="အသစ်ထည့်ရန်" className="gs_mt">
				<div className="row">
					<div className="col-md-6">
						<p>နေရပ်</p>
						<Input value={state} onChange={(txt) => setState(txt.target.value)} />
						<div className="mt-3 row">
							<div className="col-md-12 text-right">
								<Link to="/address">
									<Button className="mr-3">Cancel</Button>
								</Link>
								<Button type="primary" onClick={onCreateAddress}>Save</Button>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</Drawer>
	);
};

export default AddressCreate;
