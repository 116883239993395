import {
  FETCH_GOLD_RPICE_REQUEST,
  FETCH_GOLD_RPICE_SUCCESS,
  FETCH_GOLD_RPICE_FAILURE,
  GOLD_PRICE_DETAIL_REQUEST,
  GOLD_PRICE_DETAIL_SUCCESS,
  GOLD_PRICE_DETAIL_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  goldPrices: [],
  loading: false,
  singleGoldPrice: {}
};

export function goldprices(state = initialState, action) {
  switch (action.type) {
    case FETCH_GOLD_RPICE_REQUEST:
      return {
        ...state,
        loading: action.payload
      }
    case FETCH_GOLD_RPICE_SUCCESS:
      return {
        ...state,
        goldPrices: action.payload
      };
    case FETCH_GOLD_RPICE_FAILURE:
      return {
        ...state,
        goldPrices: [],
        loading: false,
      }
    case GOLD_PRICE_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case GOLD_PRICE_DETAIL_SUCCESS:
      return {
        ...state,
        singleGoldPrice: action.payload
      };
    case GOLD_PRICE_DETAIL_FAILURE:
      return {
        ...state,
        singleGoldPrice: {},
        loading: false
      }
    default:
      return state;
  }
}
