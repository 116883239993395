import {
  FETCH_SALE_PRICE_REQUEST,
  FETCH_SALE_PRICE_SUCCESS,
  FETCH_SALE_PRICE_FAILURE,
  DETAIL_SALE_PRICE_REQUEST,
  DETAIL_SALE_PRICE_SUCCESS,
  DETAIL_SALE_PRICE_FAILURE,
  CREATE_SALE_PRICE_SUCCESS,
  UPDATE_SALE_PRICE_SUCCESS,
  DELETE_SALE_PRICE_SUCCESS,
} from "../constants/actionTypes";
import { salePriceServices } from "../services/saleprice";
import history from "../history";

function fetchSalePrice() {
  function request(payload) {
    return { type: FETCH_SALE_PRICE_REQUEST, payload };
  }
  function success(payload) {
    return { type: FETCH_SALE_PRICE_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_SALE_PRICE_FAILURE, payload };
  }
  return dispatch => {
    dispatch(request(true));
    salePriceServices
      .fetchSalePrice()
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(false));
      })
      .catch(err => dispatch(failure(err.message)));
  };
}

function detailSalePrice(data) {
  function request(payload) {
    return { type: DETAIL_SALE_PRICE_REQUEST, payload };
  }
  function success(payload) {
    return { type: DETAIL_SALE_PRICE_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: DETAIL_SALE_PRICE_FAILURE, payload };
  }
  return dispatch => {
    dispatch(request(true));
    salePriceServices
      .detailSalePrice(data.id)
      .then(payload => {
        dispatch(success(payload));
        dispatch(request(false));
      })
      .catch(err => dispatch(failure(err.message)));
  };
}

function createSalePrice(data) {
  function create(payload) {
    return { type: CREATE_SALE_PRICE_SUCCESS, payload };
  }
  return dispatch => {
    salePriceServices.createSalePrice(data).then(payload => {
      dispatch(create(payload));
      history.push("/saleitems");
    });
  };
}

function updateSalePrice(data) {
  console.log(data);
  function success(payload) {
    return { type: UPDATE_SALE_PRICE_SUCCESS, payload };
  }
  return dispatch => {
    salePriceServices.updateSalePrice(data).then(payload => {
      dispatch(success(payload));
      history.push("/saleitems");
    });
  };
}

function deleteSalePrice(data) {
  function success(payload) {
    return { type: DELETE_SALE_PRICE_SUCCESS, payload };
  }
  function request(payload) {
    return { type: FETCH_SALE_PRICE_REQUEST, payload };
  }
  function getAllSalePrice(payload) {
    return { type: FETCH_SALE_PRICE_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_SALE_PRICE_FAILURE, payload };
  }
  return dispatch => {
    salePriceServices.deleteSalePrice(data).then(payload => {
      dispatch(success(payload));
      dispatch(request(true));
      salePriceServices.fetchSalePrice().then(payload => {
        dispatch(getAllSalePrice(payload));
        dispatch(request(false));
      }).catch(err => dispatch(failure(err.message)))
    });
  };
}

export const salePriceActions = {
  fetchSalePrice,
  detailSalePrice,
  createSalePrice,
  updateSalePrice,
  deleteSalePrice
};
