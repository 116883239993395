import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

async function fetchSubCategories(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/subcategory/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function getShowItems(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/showitem/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

export const subCategoryServices = {
  fetchSubCategories,
  getShowItems
}
