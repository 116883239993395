import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Select, Input, Button } from "antd";
import Drawer from "../../components/Drawer";
import { categoryActions } from "../../actions/category";
import { Link } from "react-router-dom";
const { Option } = Select;

class CategoryEdit extends React.Component {
  state = {
    id: 0,
    name: "",
    parent: 0
  };

  componentDidMount() {
    this.props.getAllCategories();
    this.props.getDetail({ id: this.props.match.params.id });
  }

  componentWillReceiveProps(prevProps) {
    const { singleCategory } = prevProps;
    console.log(singleCategory)

    this.setState({
      id: singleCategory.id,
      name: singleCategory.name,
      parent: singleCategory.parent ? singleCategory.parent.name : null,
    });
  }

  onChangeText = txt => {
    this.setState({ name: txt.target.value });
  };

  onSelectChange = e => {
    this.setState({ parent: e.key });
  };

  onFormSubmit = e => {
    e.preventDefault();
    const payload = {
      id: this.state.id,
      name: this.state.name,
      parent: this.state.parent
    };
    this.props.updateCategory(payload);
  };
  render() {
    const { name, parent } = this.state;
    const { categories, loading } = this.props;
    return (
      <Drawer>
        <Spin spinning={loading} tip="Loading...">
          <Card title="အမျိုးအစား ပြင်ရန်" className="gs_mt">
            <div className="row">
              <div className="col-md-6">
                <Select
                  labelInValue
                  onChange={this.onSelectChange}
                  style={{ width: "80%" }}
                  defaultValue={{ key: parent, value: parent }}   
                  value={{ key: parent, value: parent }}         
                >
                  {categories
                    .filter(item => {
                      return item.parent === 0;
                    })
                    .map((item, index) => {
                      return (
                        <Option value={item.id} key={index}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-md-6">
                <Input
                  value={name}
                  onChange={this.onChangeText}
                  placeholder="အမျိုးအစား"
                />
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-md-12 text-right">
                <Link to="/categories">
                  <Button className="mr-3">Cancel</Button>
                </Link>
                <Button type="primary" onClick={this.onFormSubmit}>
                  Save
                </Button>
              </div>
            </div>
          </Card>
        </Spin>
      </Drawer>
    );
  }
}

const mapStateToProps = ({ categories }) => {
  return {
    singleCategory: categories.singleCategory,
    categories: categories.categories,
    loading: categories.loading
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getDetail: data => dispatch(categoryActions.detailCategory(data)),
    getAllCategories: () => dispatch(categoryActions.getAllCategories()),
    updateCategory: data => dispatch(categoryActions.updateCategory(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryEdit);
