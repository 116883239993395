import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../components/Drawer";
import { Card, Button, Table } from "antd";
import { Link } from "react-router-dom";
import { mortgageActions } from "../../actions/mortgage";

const MortgageList = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.mortgages);

  useEffect(() => {
    dispatch(mortgageActions.fetchMortgage());
  }, [dispatch]);

  const onDeleteMortgage = id => {
    dispatch(mortgageActions.deleteMortgage({ id }));
  };

  const columns = [
    {
      title: "နံပါတ်",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "အပေါင် နံပါတ်",
      dataIndex: "code",
      key: "code"
    },
    {
      title: "ရက်စွဲ",
      render: (text, record) => {
        return <span>{`${record.year}-${record.month}-${record.day}`}</span>;
      }
    },
    {
      title: "အမျိုးအမည်",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "အရေအတွက်",
      dataIndex: "qty",
      key: "qty"
    },
    {
      title: "ဈေးနှုန်း",
      dataIndex: "price",
      key: "price"
    },

    {
      title: "အတိုးနှုန်း",
      render: (text, record) => {
        return <span>{`${record.kyat}-${record.pyar}`}</span>;
      }
    },
    {
      title: "သတ်မှတ်ကာလ",
      dataIndex: "timeline",
      key: "timeline"
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Link to={`/mortgage/${record.id}/addition`}>
            <button className="btn btn-secondary"> ထပ်ယူ </button>
          </Link>
        );
      }
    },
    {
      title: "",
      render: () => {
        return <button className="btn btn-success"> ရွေးရန် </button>;
      }
    },
    {
      title: "",
      render: () => {
        return <button className="btn btn-info"> အတိုးသတ် </button>;
      }
    },
    {
      title: "လုပ်ဆောင်ချက်",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <React.Fragment>
            <button className="btn btn-light"> ဘောင်ချာထုတ်ရန် </button>
          </React.Fragment>
        );
      }
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Link to={`/mortgage/${record.id}`}>
            <button className="btn btn-warning"> ကြည့်ရန် </button>
          </Link>
        );
      }
    },
    {
      title: "",
      render: (text, record) => {
        return (
          // <Link to="/mortgageEdit">
          <Link to={`/mortgageEdit/${record.id}`}>
            <button className="btn btn-primary">ပြင်ရန်</button>
          </Link>
        );
      }
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <button
            className="btn btn-danger"
            onClick={() => onDeleteMortgage(record.id)}
          >
            {" "}
            ဖျက်ရန်
          </button>
        );
      }
    }
  ];
  return (
    <Drawer>
      <Card
        title="အပေါင်စာရင်း"
        className="gs_mt"
        extra={
          <Link to="/mortgageAdd">
            <Button type="primary"> အသစ်ထည့်ရန် </Button>
          </Link>
        }
      >
        <Table
          columns={columns}
          dataSource={state.mortgages}
          rowKey="id"
          scroll={{ x: 1700 }}
        />
      </Card>
    </Drawer>
  );
};

export default MortgageList;
