import {
  FETCH_SALE_PRICE_REQUEST,
  FETCH_SALE_PRICE_SUCCESS,
  FETCH_SALE_PRICE_FAILURE,
  DETAIL_SALE_PRICE_REQUEST,
  DETAIL_SALE_PRICE_SUCCESS,
  DETAIL_SALE_PRICE_FAILURE,
  // CREATE_SALE_PRICE_SUCCESS,
  // UPDATE_SALE_PRICE_SUCCESS,
  // DELETE_SALE_PRICE_SUCCESS,
  // DELETE_SALE_PRICE_REQUEST,
  // DELETE_SALE_PRICE_FAILURE
} from "../constants/actionTypes";

const initialState = {
  salepriceList: [],
  loading: false,
  singleSalePrice: {}
};

export function saleprices(state = initialState, action) {
  switch (action.type) {
    case FETCH_SALE_PRICE_SUCCESS:
      return {
        ...state,
        salepriceList: action.payload
      };
    case FETCH_SALE_PRICE_REQUEST:
      return {
        ...state,
        loading: action.payload
      };
    case FETCH_SALE_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        salepriceList: []
      };
    case DETAIL_SALE_PRICE_REQUEST:
      return {
        ...state,
        loading: action.payload
      };
    case DETAIL_SALE_PRICE_SUCCESS:
      return {
        ...state,
        singleSalePrice: action.payload
      };
    case DETAIL_SALE_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        singleSalePrice: {}
      };
    default:
      return state;
  }
}
