import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { categoryActions } from '../../actions/category';
import { Card, Input, Button, Select } from 'antd';
import { Link } from 'react-router-dom';

const { Option } = Select;

const CategoryAdd = () => {
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.categories);

	const [ state, setState ] = useState({
		name: '',
		parent: 0,
	});

	const onSelectChange = (e) => {
		setState({ ...state, parent: e.value });
	};

	const onCreateCategory = (e) => {
		const payload = {
			name: state.name,
			parent: state.parent
		}
		dispatch(categoryActions.createCategories(payload));
	};

	useEffect(() => {
		dispatch(categoryActions.getAllCategories());
	}, [dispatch]);

	return (
		<Drawer>
			<Card title="အသစ်ထည့်ရန်" className="gs_mt">
				<div className="row">
					<div className="col-md-6">
						<Select
							labelInValue
							defaultValue={{ key: 0, label: 'အုပ်စု အမျိုးအစား' }}
							style={{ width: '80%' }}
							onChange={onSelectChange}
						>
							{categories.categories
								.filter((item) => {
									return item.parent === 0;
								})
								.map((item, index) => {
									return (
										<Option value={item.id} key={index}>
											{item.name}
										</Option>
									);
								})}
						</Select>
					</div>
					<div className="col-md-6">
						<Input value={state.name} onChange={(txt) => setState({ ...state, name: txt.target.value })} placeholder="အမျိုးအစား"/>
					</div>
				</div>
				<div className="mt-3 row">
					<div className="col-md-12 text-right">
						<Link to="/categories">
							<Button className="mr-3">Cancel</Button>
						</Link>
						<Button type="primary" onClick={onCreateCategory}>
							Save
						</Button>
					</div>
				</div>
			</Card>
		</Drawer>
	);
};

export default CategoryAdd;
