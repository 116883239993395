import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import { useDispatch, useSelector } from "react-redux";

import { Card, Input, Button, Select } from "antd";
import { mortgageActions } from "../../actions/mortgage";
import { Link } from "react-router-dom";
const { Option } = Select;

const MortgageEdit = props => {
  const dispatch = useDispatch();
  const [state, setState] = useState("");
  const singleMortgage = useSelector(state => state.mortgages.singleMortgage);
  const address = useSelector(state => state.address.addresses);
  const type = useSelector(state => state.items.items);

  const [data, setData] = useState({
    id: "",
    year: "",
    month: "",
    day: "",
    seller: "",
    address: "",
    type: "",
    qty: 0,
    kyat_thar: 0,
    pal: 0,
    yway: 0,
    price: 0,
    kyat: 0,
    pyar: 0,
    timeline: 0,
    remark: ""
  });

  useEffect(() => {
    const { id } = props.match.params;
    dispatch(mortgageActions.fetchMortgageDetail({ id }));
    setData(singleMortgage);
  }, [dispatch, props.match.params]);

  const updateMortgage = () => {
    const payload = {
      id: data.id,
      year: data.year,
      month: data.month,
      day: data.day,
      seller: data.seller,
      address: data.address,
      type: data.type,
      qty: data.qty,
      kyat_thar: data.kyat_thar,
      pal: data.pal,
      yway: data.yway,
      price: data.price,
      kyat: data.kyat,
      pyar: data.price,
      timeline: data.timeline,
      remark: data.remark
    };
    dispatch(mortgageActions.updateMortgage(payload));
  };

  const onAddressChange = evt => {
    setData({ ...data, address: evt });
  };

  const onInputChange = evt => {
    setData({ ...data, [evt.target.name]: evt.target.value });
  };
  const onTypeChange = evt => {
    setData({ ...data, type: evt });
  };

  return (
    <Drawer>
      <Card title="အပေါင် နံပါတ် ( ITM_000087 )" className="gs_mt">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <label>ရက်စွဲ</label>
                <Button
                  className="ml-2 mr-2"
                  type={state.mm_status ? "primary" : null}
                  onClick={() =>
                    setState({
                      myanmar: true,
                      mm_status: true,
                      end_status: false
                    })
                  }
                >
                  မြန်မာ
                </Button>
                <Button
                  type={state.eng_status ? "primary" : ""}
                  onClick={() =>
                    setState({
                      english: true,
                      eng_status: true,
                      mm_status: false
                    })
                  }
                >
                  {" "}
                  အင်္ဂလိပ်{" "}
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3">
                <Select
                  defaultValue={state.myanmar ? "ခုနှစ်" : "Year"}
                  value={data.year}
                  onChange={txt => setData({ ...data, year: txt })}
                  style={{ width: "100%" }}
                >
                  {state.myanmar ? (
                    <React.Fragment>
                      <Option value="၁၃၆၅">၁၃၆၅</Option>
                      <Option value="၁၃၆၄">၁၃၆၄</Option>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Option value="jack">Jack</Option>
                      <Option value="Yiminghe">yiminghe</Option>
                    </React.Fragment>
                  )}
                </Select>
              </div>
              <div className="col-md-4 mt-3">
                <Select
                  defaultValue={state.myanmar ? "လ" : "Month"}
                  value={data.month}
                  onChange={txt => setData({ ...data, month: txt })}
                  style={{ width: "100%" }}
                >
                  {state.myanmar ? (
                    <React.Fragment>
                      <Option value="တန်ခူး">တန်ခူး</Option>
                      <Option value="ကဆုန်">ကဆုန်</Option>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Option value="Jan">Jan</Option>
                      <Option value="Feb">Feb</Option>
                    </React.Fragment>
                  )}
                </Select>
              </div>
              <div className="col-md-4 mt-3">
                <Select
                  defaultValue={state.myanmar ? "ရက်" : "Day"}
                  value={data.day}
                  onChange={txt => setData({ ...data, day: txt })}
                  style={{ width: "100%" }}
                >
                  {state.myanmar ? (
                    <React.Fragment>
                      <Option value="၀၁">၀၁</Option>
                      <Option value="၀၂">၀၂</Option>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Option value="01">01</Option>
                      <Option value="02">02</Option>
                    </React.Fragment>
                  )}
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p>
              <label>ရောင်းသူ</label>
            </p>
            <Input
              name="seller"
              value={data.seller}
              onChange={evt => onInputChange(evt)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <label>နေရပ်</label>
            {/* <Input
            name="address"
            value={data.address}
            onChange={evt => onInputChange(evt)}
          /> */}
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="နေရပ်ရွေးပါ"
              name="address"
              value={data.address}
              // value={this.state.item_type_id}
              // onChange={this.onSelectChange}
              onChange={evt => onAddressChange(evt)}
              // onFocus={this.onFocus}
              // onBlur={this.onBlur}
              // onSearch={this.onSearch}
            >
              {address.map((add, index) => {
                return (
                  <Option key={index} value={add.id}>
                    {add.address}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="col-md-4">
            <label>အမျိုးအမည်</label>

            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="အမျိုးအမည်ရွေးပါ"
              name="type"
              value={data.type}
              onChange={evt => onTypeChange(evt)}
            >
              {type.map((it, index) => {
                return (
                  <Option key={index} value={it.id}>
                    {it.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="col-md-4">
            <label>အရေအတွက်</label>
            <Input
              name="qty"
              value={data.qty}
              onChange={evt => onInputChange(evt)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <label>အလေးချိန်</label>
            <div className="row">
              <div className="col-md-4 mt-3">
                <Input
                  name="kyat_thar"
                  placeholder="ကျပ်သား"
                  value={data.kyat_thar}
                  onChange={evt => onInputChange(evt)}
                />
              </div>
              <div className="col-md-4 mt-3">
                <Input
                  name="pal"
                  placeholder="ပဲ"
                  value={data.pal}
                  onChange={evt => onInputChange(evt)}
                />
              </div>
              <div className="col-md-4 mt-3">
                <Input
                  name="yway"
                  placeholder="ရွေး"
                  value={data.yway}
                  onChange={evt => onInputChange(evt)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p>ဈေးနှုန်း</p>
            <Input
              name="price"
              value={data.price}
              onChange={evt => onInputChange(evt)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 mt-3">
                <label>အတိုးနှုန်း</label>
                <Input
                  name="kyat"
                  placeholder="ကျပ်"
                  value={data.kyat}
                  onChange={evt => onInputChange(evt)}
                />
              </div>
              <div className="col-md-4 mt-3">
                <Input
                  name="pyar"
                  placeholder="ပြား"
                  value={data.pyar}
                  onChange={evt => onInputChange(evt)}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label>သတ်မှတ်ကာလ</label>
                <Select
                  defaultValue="lucy"
                  value={data.timeline}
                  onChange={txt => setData({ ...data, timeline: txt })}
                  style={{ width: "100%" }}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p>မှတ်ချက်</p>
            <Input
              name="remark"
              value={data.remark}
              onChange={evt => onInputChange(evt)}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-right">
            <Link to='/mortgage'>
              <button className="btn btn-secondary mr-3"> ရှေ့သို့ </button>
            </Link>
            <button className="btn btn-danger" onClick={updateMortgage}> ပြင်ရန်</button>
          </div>
        </div>
      </Card>
    </Drawer>

    // <Drawer>
    //   <Card title="အပေါင်နံပါတ်(ITM_000087)ပြင်ရန်" className="gs_mt">
    //     <div className="row">
    //       <div className="col-md-6">
    //         <label>ရက်စွဲ</label>
    //         <div className="row">
    //           <div className="col-md-4">
    //             <Input value={data.year} />
    //           </div>
    //           <div className="col-md-4">
    //             <Input value={data.month} />
    //           </div>
    //           <div className="col-md-4">
    //             <Input value={data.day} />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-6">
    //         <label>ရောင်းသူ</label>
    //         <Input value={data.seller} />
    //       </div>
    //     </div>

    //     <div className="row mt-3">
    //       <div className="col-md-4">
    //         <label>နေရပ်</label>
    //         {/* <Input value={data.address} /> */}

    //         <Select
    //           showSearch
    //           optionFilterProp="children"
    //           style={{ width: '100%' }}
    //           placeholder="နေရပ်ရွေးပါ"
    //           name="address"

    //           // value={this.state.item_type_id}
    //           // onChange={this.onSelectChange}
    //           onChange={evt => onAddressChange(evt)}
    //         // onFocus={this.onFocus}
    //         // onBlur={this.onBlur}
    //         // onSearch={this.onSearch}
    //         >
    //           {address.map((add, index) => {
    //             return <Option key={index} value={add.id}>{add.address}</Option>
    //           })}
    //         </Select>

    //       </div>
    //       <div className="col-md-4">
    //         <label>အမျိုးအမည်</label>
    //         {/* <Input value={data.type} /> */}
    //         <Select
    //           showSearch
    //           optionFilterProp="children"
    //           style={{ width: '100%' }}
    //           placeholder="အမျိုးအမည်ရွေးပါ"
    //           name="type"

    //           // value={this.state.item_type_id}
    //           // onChange={this.onSelectChange}
    //           onChange={evt => onTypeChange(evt)}
    //         // onFocus={this.onFocus}
    //         // onBlur={this.onBlur}
    //         // onSearch={this.onSearch}
    //         >
    //           {type.map((it, index) => {
    //             return <Option key={index} value={it.id}>{it.name}</Option>
    //           })}
    //         </Select>
    //       </div>
    //       <div className="col-md-4">
    //         <label>အရေအတွက်</label>
    //         <Input value={data.qty} />
    //       </div>
    //     </div>

    //     <div className="row mt-3">
    //       <div className="col-md-6">
    //         <label>အလေးချိန်</label>
    //         <div className="row">
    //           <div className="col-md-4">
    //             <Input value={data.kyat_thar} />
    //           </div>
    //           <div className="col-md-4">
    //             <Input value={data.pal} />
    //           </div>
    //           <div className="col-md-4">
    //             <Input value={data.yway} />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-6">
    //         <label>ဈေးနှုန်း</label>
    //         <Input value={data.price} />
    //       </div>
    //     </div>

    //     <div className="row mt-3">
    //       <div className="col-md-6">
    //         <div className="row">
    //           <div className="col-md-4 mt-3">
    //             <label>အတိုးနှုန်း</label>
    //             <Input value={data.kyat} />
    //           </div>
    //           <div className="col-md-4" style={{ marginTop: 45 }}>
    //             <Input value={data.pyar} />
    //           </div>
    //           <div className="col-md-4 mt-3">
    //             <label>သတ်မှတ်ကာလ</label>
    //             <Select
    //               disabled
    //               value={data.timeline}
    //               style={{ width: "100%" }}
    //             ></Select>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-6">
    //         <p>မှတ်ချက်</p>
    //         <Input value={data.remark} />
    //       </div>
    //     </div>

    //     <div className="row mt-3">
    //       <div className="col-md-12 text-right">
    //         <button className="btn btn-secondary mr-3"> ရှေ့သို့ </button>

    //         <button className="btn btn-danger" onClick={updateMortgage}> ပြင်ရန်</button>
    //       </div>
    //     </div>
    //   </Card>
    //</Drawer >
  );
};

export default MortgageEdit;
