import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from '../../components/Drawer';
import { salePriceActions } from '../../actions/saleprice';
import { goldpriceActions } from '../../actions/goldprice';
import { Card, Spin, Input, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';

class SaleItemEdit extends Component {
	state = {
		id          : 0,
		item_code   : '',
		item_name   : '',
		kyat_thar   : '',
		pal         : '',
		yway        : '',
		price       : 0,
		qty         : 0,
		ayault      : 0,
		stonevalue  : 0,
		category    : '',
		goldprice_id : 0,
		remark      : ''
	};

	componentDidMount() {
		const { id } = this.props.match.params;
		this.props.getDetail({ id });
		this.props.getAllGoldPrices();
	}

	componentWillReceiveProps(prevProps) {
		const { data } = prevProps;
		this.setState((prevProps) => ({
			...data
		}));
	}

	onInputChange = (evt) => {
		this.setState({ [evt.target.name]: evt.target.value });
	};

	onSelectChange = (evt) => {
		this.setState({ category_id: evt.key, category: evt.label });
	};
	onFormSubmit = (e) => {
		this.props.updateSalePrice(this.state);
	};

	render() {
		const { loading, goldPrices } = this.props;
		const {
			item_code,
			item_name,
			kyat_thar,
			pal,
			yway,
			qty,
			price,
			ayault,
			stonevalue,
			goldprice_id,
			remark
		} = this.state;
		return (
			<Drawer>
				<Spin spinning={loading} tip="Fetching data...">
					<Card title="ပြင်ရန">
						<div className="row mb-3">
							<div className="col-md-3">
								<Input
									name="item_code"
									value={item_code}
									onChange={this.onInputChange}
									placeholder="Item Code"
								/>
							</div>
							<div className="col-md-3">
								<Input
									name="item_name"
									value={item_name}
									onChange={this.onInputChange}
									placeholder="Item Name"
								/>
							</div>
							<div className="col-md-3">
								<Input
									name="kyat_thar"
									value={kyat_thar}
									onChange={this.onInputChange}
									placeholder="Kyat thar"
								/>
							</div>
							<div className="col-md-3">
								<Input name="pal" value={pal} onChange={this.onInputChange} placeholder="Pal" />
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-md-3">
								<Input name="yway" value={yway} onChange={this.onInputChange} placeholder="Yway" />
							</div>
							<div className="col-md-3">
								<Input name="price" value={price} onChange={this.onInputChange} placeholder="Price" />
							</div>
							<div className="col-md-3">
								<Input name="qty" value={qty} onChange={this.onInputChange} placeholder="Qty" />
							</div>
							<div className="col-md-3">
								<Input
									name="ayault"
									value={ayault}
									onChange={this.onInputChange}
									placeholder="ayault"
								/>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-3">
								<Input
									name="stonevalue"
									value={stonevalue}
									onChange={this.onInputChange}
									placeholder="stonevalue"
								/>
							</div>
							<div className="col-md-3">
								ရွှေစျေးနှုန်း <br />
								<Radio.Group buttonStyle="solid" value={goldprice_id}>
									{goldPrices.map(({ id, name, price }) => (
										<Radio.Button value={id} key={id}>
											{name}
										</Radio.Button>
									))}
								</Radio.Group>
							</div>
							<div className="col-md-3">
								<Input
									name="remark"
									value={remark}
									onChange={this.onInputChange}
									placeholder="remark"
								/>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-6" />
							<div className="col-md-6 text-right">
								<Link to="/saleitems" className="pr-3">
									<Button>Cancel</Button>
								</Link>

								<Button type="primary" onClick={this.onFormSubmit}>
									Save
								</Button>
							</div>
						</div>
					</Card>
				</Spin>
			</Drawer>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDetail        : (data) => dispatch(salePriceActions.detailSalePrice(data)),
		updateSalePrice  : (data) => dispatch(salePriceActions.updateSalePrice(data)),
		getAllGoldPrices : () => dispatch(goldpriceActions.fetchGoldPrices())
	};
};

function mapStateToProps({ saleprices, goldprices }) {
	return {
		loading    : saleprices.loading,
		data       : saleprices.singleSalePrice,
		goldPrices : goldprices.goldPrices
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleItemEdit);
