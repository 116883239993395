import {
  FETCH_GOLD_RPICE_REQUEST,
  FETCH_GOLD_RPICE_SUCCESS,
  FETCH_GOLD_RPICE_FAILURE,

  CREATE_GOLD_PRICE_SUCCESS,
  GOLD_PRICE_DETAIL_REQUEST,
  GOLD_PRICE_DETAIL_SUCCESS,
  GOLD_PRICE_DETAIL_FAILURE,
  UPDATE_GOLD_PRICE_SUCCESS,
  DELETE_GOLD_PRICE_SUCCESS
} from "../constants/actionTypes";
import { goldPriceServices } from "../services/goldprice";
import history from '../history';

function fetchGoldPrices() {
  function request(payload) {
    return { type: FETCH_GOLD_RPICE_REQUEST, payload }
  }
  function success(payload) {
    return { type: FETCH_GOLD_RPICE_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_GOLD_RPICE_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true));
    goldPriceServices.fetchGoldPrices().then(payload => {
      dispatch(success(payload));
      dispatch(request(false));
    }).catch(err => dispatch(failure(err.message)))
  };
}

function detailGoldPrice(data) {
  function success(payload) {
    return { type: GOLD_PRICE_DETAIL_SUCCESS, payload };
  }
  function request(payload) {
    return { type: GOLD_PRICE_DETAIL_REQUEST, payload }
  }
  function failure(payload) {
    return { type: GOLD_PRICE_DETAIL_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true))
    goldPriceServices.detailGoldPrice(data).then(payload => {
      dispatch(success(payload));
      dispatch(request(false))
    }).catch(err => dispatch(failure(err.message)))
  };
}

function createGoldPrice(data) {
  function success(payload) {
    return { type: CREATE_GOLD_PRICE_SUCCESS, payload };
  }
  return dispatch => {
    goldPriceServices.createGoldPrice(data).then(payload => {
      dispatch(success(payload));
      history.push('/goldprice')
    });
  };
}

function updateGoldPrice(data) {
  function success(payload) {
    return { type: UPDATE_GOLD_PRICE_SUCCESS, payload }
  }
  return dispatch => {
    goldPriceServices.updateGoldPrice(data)
      .then(payload => {
        dispatch(success(payload));
        history.push('/goldprice');
      })
  }
}

function deleteGoldPrice(data) {
  function success(payload) {
    return { type: DELETE_GOLD_PRICE_SUCCESS, payload }
  }
  function request(payload) {
    return { type: FETCH_GOLD_RPICE_REQUEST, payload }
  }
  function getAllGP(payload) {
    return { type: FETCH_GOLD_RPICE_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: FETCH_GOLD_RPICE_FAILURE, payload }
  }
  return dispatch => {
    goldPriceServices.deleteGoldPrice(data)
      .then(payload => {
        dispatch(success(payload))
        dispatch(request(true));
        goldPriceServices.fetchGoldPrices().then(payload => {
          dispatch(getAllGP(payload));
          dispatch(request(false));
        }).catch(err => dispatch(failure(err.message)))
      })
  }
}

export const goldpriceActions = {
  fetchGoldPrices,
  detailGoldPrice,
  createGoldPrice,
  updateGoldPrice,
  deleteGoldPrice
};
