import {
  PRINT_VOUCHER_SUCCESS,
  SAVE_CUSTOMERS_INFORMATION,
  SEND_CODE_SUCCESS,
  FETCH_PRINT_VOUCHER_SUCCESS
} from "../constants/actionTypes";
import { printServices } from '../services/print';
import history from '../history';

function createPrintVoucher(data) {
  function success(payload) {
    return { type: PRINT_VOUCHER_SUCCESS, payload }
  }
  return dispatch => {
    printServices.createPrintVoucher(data)
      .then(payload =>{ 
        dispatch(success(payload))
        history.push('/salehome')
      })
  }
}

function fetchPrintVoucher(data) {
  function success(payload) {
    return { type: FETCH_PRINT_VOUCHER_SUCCESS, payload }
  }
  return dispatch => {
    printServices.fetchPrintVoucher(data)
      .then(payload =>{ 
        dispatch(success(payload))
      })
  }
}

function saveCustomersInformation(data) {
  function success(payload) {
    return { type: SAVE_CUSTOMERS_INFORMATION, payload }
  }
  return dispatch => {
    dispatch(success(data))
 
  }
}

function sendCode(data) {
  function success(payload) {
    return { type: SEND_CODE_SUCCESS, payload }
  }
  return dispatch => {
    printServices.sendCode(data)
      .then(payload => {
        dispatch(success(payload))
      })
  }
}



export const printActions = {
  createPrintVoucher,
  fetchPrintVoucher,
  saveCustomersInformation,
  sendCode
}