import React from "react";
import { Router, Route } from "react-router-dom";

// pages
import Dashboard from './pages/dashboard/Dashboard';

import AddressList from "./pages/address/AddressList";
import AddressCreate from "./pages/address/AddressCreate";
import AddressEdit from "./pages/address/AddressEdit";

import RemarkList from "./pages/remark/RemarkList";
import RemarkAdd from './pages/remark/RemarkAdd';
import RemarkEdit from './pages/remark/RemarkEdit';

import ItemList from "./pages/item/ItemList";
import ItemAdd from './pages/item/ItemAdd';
import ItemEdit from './pages/item/ItemEdit';

import MortgageList from './pages/mortgage/MortgageList';
import MortgageAdd from './pages/mortgage/MortgageAdd';
import MortgageEdit from './pages/mortgage/MortgageEdit';
import MortgageDetail from './pages/mortgage/MortgageDetail';
import MortgageAddition from './pages/mortgage/MortgageAddition';


import GoldPriceList from './pages/goldprice/GoldPriceList';
import GoldPriceAdd from './pages/goldprice/GoldPriceAdd';
import GoldPriceEdit from './pages/goldprice/GoldPriceEdit';

import CategoryList from './pages/category/CategoryList';
import CategoryAdd from './pages/category/CategoryAdd';
import CategoryEdit from './pages/category/CategoryEdit';

import SalesItemsList from './pages/salesitems/SaleItemsList';
import SaleItemsAdd from './pages/salesitems/SaleItemsAdd';
import SaleItemEdit from './pages/salesitems/SaleItemEdit';
import salesCatList from './pages/salesitems/salesCatList';

import salesPortal from './pages/salesitems/salesPortal';
import subCategory from './pages/salesitems/subCategory';
import ShowItems from './pages/salesitems/showItem';
import print from './pages/salesitems/print';
import SaleHome from './pages/salesitems/SaleHome';
import printInvoice from './pages/salesitems/printInvoice';
import saleLogin from './pages/salesitems/saleLogin';

import history from "./history";
import "./App.css";

function App() {
  return (
    <Router history={history}>
      <Route exact path='/' component={Dashboard} />

      <Route exact path="/address" component={AddressList} />
      <Route path="/ads/create" component={AddressCreate} />
      <Route path="/address/:id" component={AddressEdit} />

      <Route exact path="/item" component={ItemList} />
      <Route path='/itemAdd' component={ItemAdd} />
      <Route path='/item/:id' component={ItemEdit} />

      <Route exact path="/remark" component={RemarkList} />
      <Route path='/remarkAdd' component={RemarkAdd} />
      <Route path='/remark/:id' component={RemarkEdit} />

      <Route exact path='/mortgage' component={MortgageList} />
      <Route path='/mortgageAdd' component={MortgageAdd} />
      <Route path='/mortgageEdit/:id' component={MortgageEdit} />
      <Route exact path='/mortgage/:id' component={MortgageDetail} />
      <Route path='/mortgage/:id/addition' component={MortgageAddition} />


      <Route exact path="/goldprice" component={GoldPriceList} />
      <Route path="/goldpriceAdd" component={GoldPriceAdd} />
      <Route path="/goldprice/:id" component={GoldPriceEdit} />

      <Route exact path='/categories' component={CategoryList} />
      <Route path='/categoryAdd' component={CategoryAdd} />
      <Route path='/category/:id' component={CategoryEdit} />

      <Route exact path='/saleitems' component={SalesItemsList} />
      <Route path='/saleitemAdd' component={SaleItemsAdd} />
      <Route path='/saleitem/:id' component={SaleItemEdit} />
      <Route exact path='/salesCatList' component={salesCatList} />


      <Route exact path='/salesPortal' component={salesPortal} />
      <Route exact path='/subCategory' component={subCategory} />
      <Route exact path='/showItem/:id' component={ShowItems} />
      <Route exact path='/print/:id' component={print} />
      <Route exact path='/salehome' component={SaleHome} />
      <Route exact path='/printInvoice' component={printInvoice} />
      <Route path='/salelogin' component={saleLogin} />

    </Router>
  );
}

export default App;
