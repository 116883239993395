import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

async function fetchSalePrice(payload) {
  return axios({
    method: "GET",
    url: `${apiEndpoint}/saleprice`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function detailSalePrice(payload) {
  const data = await axios({
    method: "GET",
    url: `${apiEndpoint}/saleprice/${payload}`,
    data: payload
  });
  return handleResponse(data.data);
}

async function createSalePrice(payload) {
  return axios({
    method: "POST",
    url: `${apiEndpoint}/saleprice`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function updateSalePrice(payload) {
  return axios({
    method: "PUT",
    url: `${apiEndpoint}/saleprice/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

async function deleteSalePrice(payload) {
  return axios({
    method: "DELETE",
    url: `${apiEndpoint}/saleprice/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data));
}

export const salePriceServices = {
  fetchSalePrice,
  detailSalePrice,
  createSalePrice,
  updateSalePrice,
  deleteSalePrice
};
