import React, { useState } from "react";
import Drawer from "../../components/Drawer";
import { useDispatch } from "react-redux";
import { Card, Button, Input } from "antd";
import { goldpriceActions } from '../../actions/goldprice';
import { Link } from "react-router-dom";

const GoldPriceAdd = () => {
  const [data, setData] = useState({
    name: '',
    price: ''
  });
  const dispatch = useDispatch();

  const onInputChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const onCreateGoldPrice = () => {
    dispatch(goldpriceActions.createGoldPrice(data));

  }
  return (
    <Drawer>
      <Card title="ရွေစျေးနှုန်းအသစ်ထည့်ရန်">

        <div className="row">
          <div className="col-md-3">
            Name<br />
            <Input value={data.name} name='name' onChange={onInputChange} />
          </div>
          <div className="col-md-3">
            Price<br />
            <Input value={data.price} name='price' onChange={onInputChange} />
          </div>
        </div>

        <div className="mt-3 row">
          <div className="col-md-3">
            <Link to="/goldprice">
              <Button className="mr-3">Cancel</Button>
            </Link>
          </div>
          <div className='col-md-3 text-right'>
            <Button type="primary" onClick={onCreateGoldPrice}>
              Save
            </Button>
          </div>
        </div>

      </Card>
    </Drawer>
  );
};

export default GoldPriceAdd;
