import {
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
  FETCH_ADDRESS_FAILURE,
  // CREATE_ADDRESS_SUCCESS,
  // UPDATE_ADDRESS_SUCCESS,
  // DELETE_ADDRESS_SUCCESS,
  DETAIL_ADDRESS_SUCCESS,
  DETAIL_ADDRESS_REQUEST,
  DETAIL_ADDRESS_FAILURE,
} from '../constants/actionTypes';
const initialState = {
  addresses: [],
  loading: false,
  singleAddress: {},
  errorMsg: {},
};

export function address(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADDRESS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case FETCH_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        addresses: [],
      }
    case DETAIL_ADDRESS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case DETAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        singleAddress: action.payload,
      }
    case DETAIL_ADDRESS_FAILURE:
      return {
        ...state,
        singleAddress: {},
        loading: false,
      }
    default:
      return state;
  }
}
