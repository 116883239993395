import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../components/Drawer";
import { Spin, Table, Button, Card, Popconfirm } from "antd";
import { categoryActions } from "../../actions/category";
import { Link } from "react-router-dom";

const CategoryList = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.categories);
  const loading = useSelector(state => state.categories.loading);

  useEffect(() => {
    dispatch(categoryActions.getAllCategories());
  }, [dispatch]);

  const onCategoryDelete = id => {
    dispatch(categoryActions.deleteCategory({ id }));
  };

  const columns = [
    {
      title: "နံပါတ်",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "အမျိုးအစား",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "လုပ်ဆောင်ချက်",
      render: (text, record) => {
        return (
          <Link to={`/category/${record.id}`}>
            <Button type="primary"> ပြင်ရန် </Button>
          </Link>
        );
      }
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Popconfirm
            title={`Are you sure delete this ${record.name}?`}
            onConfirm={() => onCategoryDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              ဖျက်ရန်
            </Button>
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <Drawer>
      <Spin spinning={loading} tip="Loading...">
        <Card
          title="အမျိုးအစား စာရင်း"
          extra={
            <span>
              <Link to="/categoryAdd">
                <Button type="primary"> အသစ်ထည့်ရန် </Button>
              </Link>
            </span>
          }
        >
          <Table dataSource={state.categories} columns={columns} rowKey="id" />
        </Card>
      </Spin>
    </Drawer>
  );
};

export default CategoryList;
