import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '../../components/Drawer';
import { Card, Button, Select, Input } from 'antd';
import { mortgageActions } from '../../actions/mortgage';
import { addressActions } from '../../actions/address';
import { itemActions } from '../../actions/item';
import { connect } from 'react-redux';
const { Option } = Select;

const MortgageAdd = (props) => {
	const dispatch = useDispatch();
	const [ state, setState ] = useState({
		myanmar    : true,
		english    : false,
		mm_status  : true,
		end_status : false
	});
	const [ address ] = useState(props.addresses);
	const [ type ] = useState(props.items);

	const [ data, setData ] = useState({
		year      : '',
		month     : '',
		day       : '',
		seller    : '',
		address   : '',
		type      : '',
		qty       : 0,
		kyat_thar : 0,
		pal       : 0,
		yway      : 0,
		price     : 0,
		kyat      : 0,
		pyar      : 0,
		timeline  : 0,
		remark    : ''
	});

	const onInputChange = (evt) => {
		setData({ ...data, [evt.target.name]: evt.target.value });
	};
	const onTypeChange = (evt) => {
		setData({ ...data, type: evt });
	};
	const onAddressChange = (evt) => {
		setData({ ...data, address: evt });
	};

	const onFormSubmit = () => {
		dispatch(mortgageActions.addMortgage(data));
	};

	return (
		<Drawer>
			<Card title="အပေါင် နံပါတ် ( ITM_000087 )" className="gs_mt">
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<label>ရက်စွဲ</label>
								<Button
									className="ml-2 mr-2"
									type={state.mm_status ? 'primary' : null}
									onClick={() =>
										setState({
											myanmar    : true,
											mm_status  : true,
											end_status : false
										})}
								>
									မြန်မာ
								</Button>
								<Button
									type={state.eng_status ? 'primary' : ''}
									onClick={() =>
										setState({
											english    : true,
											eng_status : true,
											mm_status  : false
										})}
								>
									{' '}
									အင်္ဂလိပ်{' '}
								</Button>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 mt-3">
								<Select
									defaultValue={state.myanmar ? 'ခုနှစ်' : 'Year'}
									value={data.year}
									onChange={(txt) => setData({ ...data, year: txt })}
									style={{ width: '100%' }}
								>
									{state.myanmar ? (
										<React.Fragment>
											<Option value="၁၃၆၅">၁၃၆၅</Option>
											<Option value="၁၃၆၄">၁၃၆၄</Option>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Option value="jack">Jack</Option>
											<Option value="Yiminghe">yiminghe</Option>
										</React.Fragment>
									)}
								</Select>
							</div>
							<div className="col-md-4 mt-3">
								<Select
									defaultValue={state.myanmar ? 'လ' : 'Month'}
									value={data.month}
									onChange={(txt) => setData({ ...data, month: txt })}
									style={{ width: '100%' }}
								>
									{state.myanmar ? (
										<React.Fragment>
											<Option value="တန်ခူး">တန်ခူး</Option>
											<Option value="ကဆုန်">ကဆုန်</Option>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Option value="Jan">Jan</Option>
											<Option value="Feb">Feb</Option>
										</React.Fragment>
									)}
								</Select>
							</div>
							<div className="col-md-4 mt-3">
								<Select
									defaultValue={state.myanmar ? 'ရက်' : 'Day'}
									value={data.day}
									onChange={(txt) => setData({ ...data, day: txt })}
									style={{ width: '100%' }}
								>
									{state.myanmar ? (
										<React.Fragment>
											<Option value="၀၁">၀၁</Option>
											<Option value="၀၂">၀၂</Option>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Option value="01">01</Option>
											<Option value="02">02</Option>
										</React.Fragment>
									)}
								</Select>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<p>
							<label>ရောင်းသူ</label>
						</p>
						<Input name="seller" value={data.seller} onChange={(evt) => onInputChange(evt)} />
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-4">
						<label>နေရပ်</label>
						<Select
							showSearch
							optionFilterProp="children"
							style={{ width: '100%' }}
							placeholder="နေရပ်ရွေးပါ"
							name="address"
							onChange={(evt) => onAddressChange(evt)}
						>
							{address.map((add, index) => {
								return (
									<Option key={index} value={add.id}>
										{add.address}
									</Option>
								);
							})}
						</Select>
					</div>
					<div className="col-md-4">
						<label>အမျိုးအမည်</label>

						<Select
							showSearch
							optionFilterProp="children"
							style={{ width: '100%' }}
							placeholder="အမျိုးအမည်ရွေးပါ"
							name="type"
							onChange={(evt) => onTypeChange(evt)}
						>
							{type.map((it, index) => {
								return (
									<Option key={index} value={it.id}>
										{it.name}
									</Option>
								);
							})}
						</Select>
					</div>
					<div className="col-md-4">
						<label>အရေအတွက်</label>
						<Input name="qty" value={data.qty} onChange={(evt) => onInputChange(evt)} />
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-6">
						<label>အလေးချိန်</label>
						<div className="row">
							<div className="col-md-4 mt-3">
								<Input
									name="kyat_thar"
									placeholder="ကျပ်သား"
									value={data.kyat_thar}
									onChange={(evt) => onInputChange(evt)}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<Input
									name="pal"
									placeholder="ပဲ"
									value={data.pal}
									onChange={(evt) => onInputChange(evt)}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<Input
									name="yway"
									placeholder="ရွေး"
									value={data.yway}
									onChange={(evt) => onInputChange(evt)}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<p>ဈေးနှုန်း</p>
						<Input name="price" value={data.price} onChange={(evt) => onInputChange(evt)} />
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-4 mt-3">
								<label>အတိုးနှုန်း</label>
								<Input
									name="kyat"
									placeholder="ကျပ်"
									value={data.kyat}
									onChange={(evt) => onInputChange(evt)}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<Input
									name="pyar"
									placeholder="ပြား"
									value={data.pyar}
									onChange={(evt) => onInputChange(evt)}
								/>
							</div>
							<div className="col-md-4 mt-3">
								<label>သတ်မှတ်ကာလ</label>
								<Select
									defaultValue="lucy"
									value={data.timeline}
									onChange={(txt) => setData({ ...data, timeline: txt })}
									style={{ width: '100%' }}
								>
									<Option value="jack">Jack</Option>
									<Option value="Yiminghe">yiminghe</Option>
								</Select>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<p>မှတ်ချက်</p>
						<Input name="remark" value={data.remark} onChange={(evt) => onInputChange(evt)} />
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-12 text-right">
						<Button className="mr-3"> မသိမ်းပါ </Button>{' '}
						<Button type="primary" onClick={onFormSubmit}>
							{' '}
							သိမ်းရန်{' '}
						</Button>{' '}
					</div>
				</div>
			</Card>
		</Drawer>
	);
};

const mapStateToProps = (state) => {
	const { address, items } = state;
	return {
		addresses : address.addresses,
		items     : items.items
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAddress : () => dispatch(addressActions.fetchAddress()),
		fetchItems   : () => dispatch(itemActions.fetchItems())
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(MortgageAdd);

