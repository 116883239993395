import {
  FETCH_SUB_CATEGORIES_SUCCESS,
  FETCH_SHOW_ITEMS_BY_ID,
  SEND_CODE_SUCCESS
} from "../constants/actionTypes";
import { subCategoryServices } from '../services/subCategory';

function fetchSubCategories(data) {
  function success(payload) {
    return { type: FETCH_SUB_CATEGORIES_SUCCESS, payload }
  }
  function cleanSaleHomeCode(payload) {
    return { type: SEND_CODE_SUCCESS, payload: [] }
  }
  return dispatch => {
    subCategoryServices.fetchSubCategories(data)
    .then(payload => {
      dispatch(success(payload));
      dispatch(cleanSaleHomeCode())
    })
  }
}

function fetchShowItems(data) {
  function success(payload) {
    return { type: FETCH_SHOW_ITEMS_BY_ID, payload }
  }
  function cleanSaleHomeCode(payload) {
    return { type: SEND_CODE_SUCCESS, payload: [] }
  }
  return dispatch => {
    subCategoryServices.getShowItems(data)
    .then(payload => {
      dispatch(success(payload));
      dispatch(cleanSaleHomeCode())
    })
  }
}


export const subCategoryActions = {
  fetchSubCategories,
  fetchShowItems
}
