export default {
  items: [
    {
      name: 'Dashboard',
      route: '/',
      icon: 'DashboardOutlined',
    },
    {
      name: 'users',
      route: '/#'
    },
    {
      name: 'အပေါင် လက်ခံ',
      route: '/mortgage',
    },
    {
      name: 'နေရပ်',
      route: '/address',
    },
    {
      name: 'အမျိုးအမည်',
      route: '/item',
    },
    {
      name: 'မှတ်ချက်',
      route: '/remark',
    },
    {
      name: 'ရွှေစျေးနှုန်း',
      route: '/goldprice',
    },
    {
      name: 'အမျိုးအစား အုပ်စု',
      route: '/categories',
    },
    {
      name: 'ပစ္စည်းထည့်ရန်',
      route: '/saleitems',
    }
  ]
}
