import { FETCH_SUB_CATEGORIES_SUCCESS, FETCH_SALES_PORTAL_SUCCESS, FETCH_SHOW_ITEMS_BY_ID } from "../constants/actionTypes";

const initialState = {
  subcategories: [],
  salesPortals: [],
  showItems: [],
};

export function subCategories(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.payload
      };
    case FETCH_SALES_PORTAL_SUCCESS:
      return {
        ...state,
        salesPortals: action.payload
      }
    case FETCH_SHOW_ITEMS_BY_ID:
      return {
        ...state,
        showItems: action.payload
      }
    default:
      return state;
  }
}
