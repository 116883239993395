import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subCategoryActions } from '../../actions/subCategory';
import { goldpriceActions } from '../../actions/goldprice';
import { printActions } from '../../actions/print';
import { Input } from 'antd';
import { Link } from 'react-router-dom';

class ShowItems extends Component {
	componentDidMount() {
		const { id } = this.props.match.params;
		this.props.getShowItems({ id });
		this.props.getGoldprices();
	}
	constructor(props) {
		super(props);
		this.state = {
			date: new Date().toLocaleString(),
			cartList_id: 0,
			cartList: [],
			item_code: 0,
			price: 0,
			price_id: 0,
			net_kyat: 0,
			net_pal: 0,
			net_yway: 0,
			net_price: 0,
			final_price: 0,
			stonevalue: 0,
			buyer_name: '',
			buyer_number: '',
			buyer_address: '',
		};
	}

	showPrice = (data) => {
		this.setState({
			name: data.name,
			price_id: data.id,
			price: data.price,
			net_price: (this.state.final_price * data.price) + (this.state.stonevalue - 0),
		});

	}

	onAddtoCart = (item) => {
		this.setState({ cartList: [item], cartList_id: item.id, item_code: item.item_code });

		const dataSource = [].concat(item);

		const ayaults = dataSource.map(item => {
			const answer = item.ayault.replace(/-/g, "").split("");
			return {
				ayault_kyat_thar: answer[0],
				ayault_pal: answer[1],
				ayault_yway: answer[2]
			}
		})

		const stoneValue = dataSource.map(item => item.stonevalue);

		let yway = dataSource.map(item => item.yway);
		let total_yway = parseInt(yway) + parseInt(ayaults[0].ayault_yway);

		let pal = dataSource.map(item => item.pal);
		let total_pal = parseInt(pal) + parseInt(ayaults[0].ayault_pal);

		let kyat_thar = dataSource.map(item => item.kyat_thar);
		let total_kyat_thar = parseInt(kyat_thar) + parseInt(ayaults[0].ayault_kyat_thar);

		if (total_yway >= 8) {
			total_pal += total_yway / 8;
		}

		total_yway %= 8;
		if (total_pal >= 16) {
			total_kyat_thar += total_pal / 16;
		}
		total_pal %= 16;
		let final_yway = (Math.floor(total_kyat_thar) * 128 + Math.floor(total_pal) * 8 + Math.floor(total_yway)) / 128;
		const net_price = final_yway;
		this.setState({
			net_kyat: Math.floor(total_kyat_thar),
			net_pal: total_pal,
			net_yway: total_yway,
			net_price,
			final_price: final_yway,
			stonevalue: stoneValue
		})
	}

	onInputChange = evt => {
		this.setState({ [evt.target.name]: evt.target.value });
	};

	saveCustomerInformation = e => {
		e.preventDefault();
		const d = new Date();
		const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
		const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
		const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
		const vCode = `${da}${mo}${ye}`;

		const payload = {
			vouchercode: vCode,
			customerName: this.state.buyer_name,
			customerphone:this.state.buyer_number,
			customeraddress: this.state.buyer_address,
			totalamount: this.state.net_price,
			salesprice_id: this.state.cartList_id,
			goldprice_id: this.state.price_id,
			saleperson_id: 2,
		}
		this.props.printVoucher(payload);
	}

	render() {
		const { net_kyat,
			net_pal,
			net_yway,
			net_price,
			stonevalue,
			buyer_name,
			buyer_number,
			buyer_address } = this.state;
		return (
			<div className="container-fluid">
				<div className="row bg-primary py-2">
					<div className="col-md-4">
						<input type="text" name="searchitem" id="" className="form-control" />
					</div>
					<div className="col-md-2" />
				</div>
				<br />
				<div className="row">
					<div className="col-md-7">
						<div className="row">
							{this.props.items && this.props.items.map((item, index) => {
								return (
									<div className="col-2" key={index}>
										<button style={{height:"100px"}} className="rounded btn btn-info p-3 mb-1 text-center text-white w-100" onClick={() => this.onAddtoCart(item)}>
											{item.item_name}
											<br/><b className="badge badge-danger">{item.item_code}</b>
											</button>
									</div>
								);
							})}
						</div>
					</div>
					<div className="col-md-5">
						<div className="row">
							<div className="col-md-12">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th colSpan="3">အကြောင်းအရာ</th>
											<th>ကျပ်</th>
											<th>ပဲ</th>
											<th>ရွှေး</th>
										</tr>

										{this.state.cartList.length
											? this.state.cartList && this.state.cartList.map((item, index) => {
												return (
													<tr key={index}>
														<td colSpan="3" className="text-right">အသားတင်ရွှေချိန်</td>
														<td>{item.kyat_thar}</td>
														<td>{item.pal}</td>
														<td>{item.yway}</td>
													</tr>
												)
											})
											: (
												<tr>
													<td colSpan="3" className="text-right">အသားတင်ရွှေချိန်</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
											)
										}

										<tr>
											<th colSpan="3" className="text-right">အလျော့</th>
											{this.state.cartList.length ?
												this.state.cartList && this.state.cartList.map((item, index) => {
													const answer = item.ayault.replace(/-/g, "").split("");
													return (
														<React.Fragment>
															<th>{answer[0]}</th>
															<th>{answer[1]}</th>
															<th>{answer[2]}</th>
														</React.Fragment>
													)
												})
												: (
													<React.Fragment>
														<th>0</th>
														<th>0</th>
														<th>0</th>
													</React.Fragment>
												)}
										</tr>
										<tr>
											<th colSpan="3" className="text-right">စုစုပေါင်းအချိန်</th>
											<th>{net_kyat}</th>
											<th>{Math.floor(net_pal)}</th>
											<th>{net_yway}</th>
										</tr>
										<tr>
											<th colSpan="3" className="text-right">ကျောက်တန်ဖိုး</th>
											<th colSpan="3" className="text-right">{stonevalue}</th>
										</tr>
										<tr>
											<div className="row">
											{this.props.goldprices && this.props.goldprices.map((item, index) => {
												return (
													<div className="col" key={index}>
														<button className="btn btn-warning m-3" onClick={() => this.showPrice(item)}>
															{item.name}
														</button>
													</div>
												)
											})}
											</div>
										</tr>
										<tr>

											<th colSpan="3" className="text-right">ကျသင့်ငွေ</th>
											<th colSpan="3" className="text-right">{Math.round(net_price / 1000) * 1000}</th>
										</tr>
										<tr>

											<th colSpan="3" className="text-right">ဝယ်သူအမည်</th>
											<th colSpan="3" className="text-right">
												<input type="text" name="buyer_name" value={buyer_name} className="form-control text-right" onChange={this.onInputChange} />
											</th>
										</tr>
										<tr>

											<th colSpan="3" className="text-right">ဝယ်ယူသူ ဖုန်းနံပါတ်</th>
											<th colSpan="3" className="text-right">
												<input type="text" name="buyer_number" value={buyer_number} className="form-control text-right" onChange={this.onInputChange} />
											</th>
										</tr>
										<tr>

											<th colSpan="3" className="text-right">ဝယ်ယူသည့် လိပ်စာ</th>
											<th colSpan="3" className="text-right">
												<input type="text" name="buyer_address" value={buyer_address} className="form-control text-right" onChange={this.onInputChange} />
											</th>
										</tr>
										<tr>
											<th colSpan="6">
												<Link to='/print' className="btn btn-secondary w-100"onClick={this.saveCustomerInformation}> ဘောက်ချာထုတ်ရန်</Link>
											</th>
										</tr>

									</thead>
								</table>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ subCategories, goldprices }) => {
	return {
		items: subCategories.showItems,
		goldprices: goldprices.goldPrices
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getShowItems: (data) => dispatch(subCategoryActions.fetchShowItems(data)),
		getGoldprices: () => dispatch(goldpriceActions.fetchGoldPrices()),
		printVoucher: data => dispatch(printActions.createPrintVoucher(data))
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowItems);
