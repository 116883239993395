import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../components/Drawer";
import { Card, Select, Input, } from "antd";
import { mortgageActions } from "../../actions/mortgage";

const MortgageDetail = props => {
  const dispatch = useDispatch();
  const mortgage = useSelector(state => state.mortgages);

  useEffect(() => {
    const { id } = props.match.params;
    console.log(props);
    dispatch(mortgageActions.fetchMortgageDetail({ id }));

  }, [dispatch, props]);

  return (
    <Drawer>
      <Card title="အပေါင် နံပါတ် ( ITM_000087 )" className="gs_mt">
        <div className="row">
          <div className="col-md-6">
            <label>ရက်စွဲ</label>
            <div className="row">
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.year} disabled />
              </div>
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.month} disabled />
              </div>
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.day} disabled />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label>ရောင်းသူ</label>
            <Input value={mortgage.singleMortgage.seller} disabled />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <label>နေရပ်</label>
            <Input value={mortgage.singleMortgage.address} disabled />
          </div>
          <div className="col-md-4">
            <label>အမျိုးအမည်</label>
            <Input value={mortgage.singleMortgage.type} disabled />
          </div>
          <div className="col-md-4">
            <label>အရေအတွက်</label>
            <Input value={mortgage.singleMortgage.qty} disabled />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <label>အလေးချိန်</label>
            <div className="row">
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.kyat_thar} disabled />
              </div>
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.pal} disabled />
              </div>
              <div className="col-md-4">
                <Input value={mortgage.singleMortgage.yway} disabled />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label>ဈေးနှုန်း</label>
            <Input value={mortgage.singleMortgage.price} disabled />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 mt-3">
                <label>အတိုးနှုန်း</label>
                <Input value={mortgage.singleMortgage.kyat} disabled />
              </div>
              <div className="col-md-4" style={{ marginTop: 45 }}>
                <Input value={mortgage.singleMortgage.pyar} disabled />
              </div>
              <div className="col-md-4 mt-3">
                <label>သတ်မှတ်ကာလ</label>
                <Select
                  disabled
                  value={mortgage.singleMortgage.timeline}
                  style={{ width: "100%" }}
                ></Select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p>မှတ်ချက်</p>
            <Input value={mortgage.singleMortgage.remark} disabled />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-right">
            <button className="btn btn-secondary mr-3"> ရှေ့သို့ </button>
            <button className="btn btn-primary mr-3"> ပြင်ရန် </button>
            <button className="btn btn-danger">ဖျက်သည်</button>
          </div>
        </div>
      </Card>
    </Drawer>
  );
};

export default MortgageDetail;
