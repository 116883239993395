import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { printActions } from '../../actions/print';

const SaleHome = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState(0);
  const [status, setStatus] = useState(false);
  const state = useSelector(state => state.print.data);
  const errorMsg = useSelector(state => state.print.errorMsg);
  const role = state.map(item => item.role);

  if (status && role[0] === 0) {
    return <Redirect to='/salesPortal' />
  } else if (status && role[0] === 1) {
    return <Redirect to='/printInvoice' />
  } else {

  }

  const onFormSubmit = e => {
    dispatch(printActions.sendCode({ code }))
    setStatus(true)
  }

  return (
    <div className="container pt-5">
      <div className="row pt-5 mt-5">
        <div className="col-md-4"></div>
        <div className="col-md-4 text-center">
         <h2> မြကျင်ကျင်</h2>
         <h4>စိန်ရွှေရတနာဆိုင်</h4>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row ">
        <div className="col-md-4"></div>
        <div className="col-md-4 bg-light rounded p-5">
          မိမိကိုယ်ပိုင်နံပါတ်ကုဒ်အား ရိုက်ထည့်ပါ။
          <div className="input-group mb-3">
            <input type="text" className="form-control"value={code} onChange={evt => setCode(evt.target.value)} placeholder="Enter Your Code" aria-label="Recipient's username" />
            <div className="input-group-append">
              <button onClick={onFormSubmit}  className="btn btn-outline-secondary" type="button">Button</button>
            </div>
          </div>
          <p className="text-left pt-2">{errorMsg}</p>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default SaleHome;




