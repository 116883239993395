import {
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,

  ITEM_DETAIL_REQUEST,
  ITEM_DETAIL_SUCCESS,
  ITEM_DETAIL_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  items: [],
  loading: false,
  singleItem: {}
};

export function items(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_ITEMS_FAILURE:
      return {
        ...state,
        items: [],
        loading: false
      }
    case ITEM_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload
      }
    case ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        singleItem: action.payload
      };
    case ITEM_DETAIL_FAILURE:
      return {
        ...state,
        singleItem: {},
        loading: false
      }
    default:
      return state;
  }
}
