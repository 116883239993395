import { PRINT_INVOICE_SUCCESS } from '../constants/actionTypes';

const initialState = {
  invoice: {}
}

export function invoices(state = initialState, action) {
  switch (action.type) {
    case PRINT_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
}