export const FETCH_ADDRESS_REQUEST = "FETCH_ADDRESS_REQUEST";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILURE = "FETCH_ADDRESS_FAILURE";

export const DETAIL_ADDRESS_REQUEST = "DETAIL_ADDRESS_REQUEST";
export const DETAIL_ADDRESS_SUCCESS = "DETAIL_ADDRESS_SUCCESS";
export const DETAIL_ADDRESS_FAILURE = "DETAIL_ADDRESS_FAILURE";

export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";

export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";

export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";

// items constants
export const FETCH_ITEMS_REQUEST = "FETCH_ITEMS_REQUEST";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";

export const CREATE_ITEMS_SUCCESS = "CREATE_ITEMS_SUCCESS";

export const ITEM_DETAIL_SUCCESS = "ITEM_DETAIL_SUCCESS";
export const ITEM_DETAIL_REQUEST = "ITEM_DETAIL_REQUEST";
export const ITEM_DETAIL_FAILURE = "ITEM_DETAIL_FAILURE";

export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";

export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

// gold price  constants
export const FETCH_GOLD_RPICE_REQUEST = "FETCH_GOLD_RPICE_REQUEST";
export const FETCH_GOLD_RPICE_SUCCESS = "FETCH_GOLD_RPICE_SUCCESS";
export const FETCH_GOLD_RPICE_FAILURE = "FETCH_GOLD_RPICE_FAILURE";

export const CREATE_GOLD_PRICE_REQUEST = "CREATE_GOLD_PRICE_REQUEST";
export const CREATE_GOLD_PRICE_SUCCESS = "CREATE_GOLD_PRICE_SUCCESS";
export const CREATE_GOLD_PRICE_FAILURE = "CREATE_GOLD_PRICE_FAILURE";

export const GOLD_PRICE_DETAIL_REQUEST = "GOLD_PRICE_DETAIL_REQUEST";
export const GOLD_PRICE_DETAIL_SUCCESS = "GOLD_PRICE_DETAIL_SUCCESS";
export const GOLD_PRICE_DETAIL_FAILURE = "GOLD_PRICE_DETAIL_FAILURE";

export const UPDATE_GOLD_PRICE_SUCCESS = "UPDATE_GOLD_PRICE_SUCCESS";

export const DELETE_GOLD_PRICE_SUCCESS = "DELETE_GOLD_PRICE_SUCCESS";

// remark constants
export const FETCH_REMARKS_REQUEST = "FETCH_REMARKS_REQUEST";
export const FETCH_REMARKS_SUCCESS = "FETCH_REMARKS_SUCCESS";
export const FETCH_REMARKS_FAILURE = "FETCH_REMARKS_FAILURE";

export const CREATE_REMARKS_SUCCESS = "CREATE_REMARKS_SUCCESS";

export const UPDATE_REMARK_SUCCESS = "UPDATE_REMARK_SUCCESS";

export const DETAIL_REMARK_REQUEST = "DETAIL_REMARK_REQUEST";
export const DETAIL_REMARK_SUCCESS = "DETAIL_REMARK_SUCCESS";
export const DETAIL_REMARK_FAILURE = "DETAIL_REMARK_FAILURE";

export const DELETE_REMARK_SUCCESS = "DELETE_REMARK_SUCCESS";

// mortgage

export const FETCH_MORTGAGE_SUCCESS = "FETCH_MORTGAGE_SUCCESS";

export const FETCH_MORTGAGE_DETAIL_SUCCESS = "FETCH_MORTGAGE_DETAIL_SUCCESS";
export const ADD_MORTGAGE_SUCCESS = "ADD_MORTGAGE_SUCCESS";

export const UDPATE_MORTGAGE = "UPDATE_MORTGATE";

export const DELETE_MORTGAGE_SUCCESS = "DELETE_MORTGAGE_SUCCESS";

// category

export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";
export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export const DETAIL_CATEGORY_REQUEST = "DETAIL_CATEGORY_REQUEST";
export const DETAIL_CATEGORY_SUCCESS = "DETAIL_CATEGORY_SUCCESS";
export const DETAIL_CATEGORY_FAILURE = "DETAIL_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";

export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";


// sale Price
export const FETCH_SALE_PRICE_REQUEST = 'FETCH_SALE_PRICE_REQUEST';
export const FETCH_SALE_PRICE_SUCCESS = 'FETCH_SALE_PRICE_SUCCESS';
export const FETCH_SALE_PRICE_FAILURE = 'FETCH_SALE_PRICE_FAILURE';

export const DETAIL_SALE_PRICE_REQUEST = 'DETAIL_SALE_PRICE_REQUEST';
export const DETAIL_SALE_PRICE_SUCCESS = 'DETAIL_SALE_PRICE_SUCCESS';
export const DETAIL_SALE_PRICE_FAILURE = 'DETAIL_SALE_PRICE_FAILURE';

export const CREATE_SALE_PRICE_SUCCESS = 'CREATE_SALE_PRICE_SUCCESS';

export const UPDATE_SALE_PRICE_SUCCESS = 'UPDATE_SALE_PRICE_SUCCESS';

export const DELETE_SALE_PRICE_SUCCESS = 'DELETE_SALE_PRICE_SUCCESS';
export const DELETE_SALE_PRICE_REQUEST = 'DELETE_SALE_PRICE_DELETE';
export const DELETE_SALE_PRICE_FAILURE = 'DELETE_SALE_PRICE_FAILURE';

// sub category
export const FETCH_SUB_CATEGORIES_SUCCESS = 'FETCH_SUB_CATEGORIES_SUCCESS';
export const FETCH_SALES_PORTAL_SUCCESS = 'FETCH_SALES_PORTAL_SUCCESS';
export const FETCH_SHOW_ITEMS_BY_ID = 'FETCH_SHOW_ITEMS_BY_ID';

// printVoucher
export const PRINT_VOUCHER_SUCCESS = 'PRINT_VOUCHER_SUCCESS';
export const FETCH_PRINT_VOUCHER_SUCCESS = 'FETCH_PRINT_VOUCHER_SUCCESS';
export const SAVE_CUSTOMERS_INFORMATION = 'SAVE_CUSTOMERS_INFORMATION';

export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';

export const PRINT_INVOICE_SUCCESS = 'PRINT_INVOICE_SUCCESS';
