import { apiEndpoint } from "../config/api";
import { handleResponse } from "../config/handleResponse";
import axios from "axios";

function fetchSalesPortal(payload) {
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/sales-category`,
    data: payload
  }).then(data => handleResponse(data.data))
}

export const salesPortalServies = {
  fetchSalesPortal
}