import { apiEndpoint } from '../config/api';
import { handleResponse } from '../config/handleResponse';
import axios from 'axios';

function printInvoice(payload){
  return axios({
    method: 'GET',
    url: `${apiEndpoint}/salesreport/${payload.id}`,
    data: payload
  }).then(data => handleResponse(data.data))
}

export const printInvoiceServices = {
  printInvoice,
}